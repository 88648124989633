import {
  type BaseQueryFn,
  createApi,
  retry,
} from '@reduxjs/toolkit/query/react'
import { type AxiosError, type AxiosRequestConfig } from 'axios'
import { toast } from 'sonner'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import { customAxios } from '@/utils/axiosHelper'
import config from '@/config'
import { currencyLocale } from '@/utils/common'
import { type AddonFeaturesType, type BillingType } from './billing.types'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (axiosError) {
      const err = axiosError as AxiosError
      if (method !== 'GET' && err.response.status !== 401) {
        ServiceErrorHandler(axiosError)
      }

      //
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      }
    }
  }

/**
 * @todo
 *
 *
 *
 * urgent...
 */

const billingApi = createApi({
  reducerPath: 'billing',
  refetchOnReconnect: true,
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.billing }), {
    maxRetries: 1,
  }),
  tagTypes: ['bill'],
  endpoints: (build) => ({
    get: build.query<BillingType, { orgId: string }>({
      providesTags: (_1, _2, meta) =>
        _1 ? [{ type: 'bill', id: meta.orgId }] : [],

      query: ({ orgId }) => ({
        method: 'GET',
        url: `/public/organisations/${orgId}/subscription`,
        extraOptions: { maxRetries: 0 },
      }),
      transformResponse(data: BillingType) {
        /*
        const plan = data.plan as any
        if (
          !['free', 'starter', 'growth', 'enterprise', 'self-serve'].includes(
            plan
          )
        ) {
          data.plan = PlanTypes.Starter
          data.is_legacy = true
        }

        data.needs_venue_upgrade = data.used_venues - data.venues
        data.needs_contact_upgrade = data.used_contacts - data.contacts

        if (data.needs_venue_upgrade < 0) {
          data.needs_venue_upgrade = 0
        }

        if (data.needs_contact_upgrade < 0) {
          data.needs_contact_upgrade = 0
        }
 */
        data.needs_venue_upgrade = 0
        data.needs_contact_upgrade = 0
        data.using_stripe = (data.subscription?.provider_id ?? '').includes(
          'sub_'
        )
        data.numberFormatter = currencyLocale(data.subscription.currency)

        /*
          data.subscriptionChange = convertSubscriptionToFrame(
            data
          )
          */
        return data
      },
    }),
    assignVenueToCode: build.mutation<
      BillingType,
      { orgId: string; code: AddonFeaturesType; serial: string }
    >({
      invalidatesTags: (_1, _2, meta) => [{ type: 'bill', id: meta.orgId }],

      query: ({ orgId, serial, code }) => ({
        method: 'POST',
        url: `/organisations/${orgId}/subscription/venues`,
        data: {
          serial,
          code,
        },
      }),
    }),
    removeVenueFromCode: build.mutation<
      BillingType,
      { orgId: string; code: AddonFeaturesType; serial: string }
    >({
      invalidatesTags: (_1, _2, meta) => [{ type: 'bill', id: meta.orgId }],

      query: ({ orgId, serial, code }) => ({
        method: 'DELETE',
        url: `/organisations/${orgId}/subscription/venues`,

        data: {
          serial,
          code,
        },
      }),
    }),

    increaseQuantity: build.mutation<
      BillingType,
      {
        orgId: string
        code: AddonFeaturesType
        increase_by: number
        idempotency_key: string
      }
    >({
      invalidatesTags: (_1, _2, meta) => [{ type: 'bill', id: meta.orgId }],

      query: ({ orgId, increase_by, code, idempotency_key }) => ({
        method: 'POST',
        url: `/organisations/${orgId}/subscription/increase-addon-quantity`,

        data: {
          increase_by,
          code,
          idempotency_key,
        },
      }),
    }),
    removeSubscription: build.mutation<
      unknown,
      {
        orgId: string
      }
    >({
      invalidatesTags: (_1, _2, meta) => [{ type: 'bill', id: meta.orgId }],

      query: ({ orgId }) => ({
        method: 'DELETE',
        url: `/admin/organisations/${orgId}/subscription`,
      }),
    }),
    unlinkStripeSubscription: build.mutation<
      unknown,
      {
        orgId: string
      }
    >({
      invalidatesTags: (_1, _2, meta) => [{ type: 'bill', id: meta.orgId }],

      query: ({ orgId }) => ({
        method: 'POST',
        url: `/admin/organisations/${orgId}/subscription/unlink-stripe-subscription`,
      }),
    }),

    linkStripeSubscription: build.mutation<
      unknown,
      {
        subscription_id: string
        orgId: string
      }
    >({
      invalidatesTags: (_1, _2, meta) => [{ type: 'bill', id: meta.orgId }],

      query: ({ orgId, subscription_id }) => ({
        method: 'POST',
        url: `/admin/organisations/${orgId}/subscription/link-stripe-subscription`,

        data: {
          subscription_id,
        },
      }),
    }),
    stripePortal: build.query<{ url: string }, { org_id: string }>({
      query: ({ org_id }) => ({
        url: `/organisations/${org_id}/subscription/stripe-portal`,
        method: 'POST',
        extraOptions: { maxRetries: 0 },
      }),
    }),
    createCustomSubscription: build.mutation<
      unknown,
      {
        orgId: string
        data: {
          addons: { code: string; quantity: number }[]
          expires_at: Date
          confirm_overwrite: boolean | undefined
        }
      }
    >({
      query: ({ orgId, data }) => ({
        method: 'POST',
        url: `/admin/organisations/${orgId}/subscription`,

        data,
      }),
    }),
    /**
     * SMS
     */
    smsMetadata: build.query<
      {
        product: {
          name: string
          default_price: {
            unit_amount: number
            currency: string
            tax_behavior: string
          }
        }
        allowed_range: { min: string; max: string }
        payment_methods: {
          id: string
          type: string
          card: {
            brand: string
            exp_month: string
            exp_year: string
            last4: string
          }
        }[]
      },
      { orgId: string }
    >({
      query: ({ orgId }) => ({
        url: `/organisations/${orgId}/subscription/stripe/purchase-sms-credits/metadata`,
        method: 'get',
        extraOptions: { maxRetries: 0 },
      }),
    }),
    purchaseSmsCredits: build.mutation<
      unknown,
      {
        orgId: string
        data: {
          quantity: number
          payment_method_id: string
        }
      }
    >({
      query: ({ orgId, data }) => ({
        url: `/organisations/${orgId}/subscription/stripe/purchase-sms-credits`,
        method: 'post',
        data,
      }),
      transformResponse: (data) => {
        toast.success(data.message)
        return data
      },
    }),
    /**
     * SMS END
     */
  }),
})

export default billingApi
