/* eslint-disable camelcase -- allow */
import type { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { retry, createApi } from '@reduxjs/toolkit/query/react'
import type { AxiosError, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import { isEqual } from 'lodash'
import { toast } from 'sonner'
import { parseISO } from 'date-fns'
import { buildUrl } from '@/utils/common'
import { customAxios } from '@/utils/axiosHelper'
import { ServiceErrorHandler } from '@/connect-types/backend/service'
import type { ServicePaginatedResponseType } from '@/connect-types/backend/service'
import config from '@/config'
import { shouldBailout } from '../helpers'
import type {
  GuestPaymentType,
  PaymentPlanType,
  PaymentUpsertType,
  RadiusGroupPolicyType,
  UniFiConfigType,
  UniFiControllerType,
  WiFiConfigType,
  WiFiImpressionsType,
  WiFiInformType,
  WiFiSessionType,
  WifiVendorType,
} from './types'
import {
  type SystemSchemaType,
  type InterfaceSchemaType,
  type DNSSchemaType,
  type FirewallSchemaType,
  type PoolSchemaType,
  type AddressSchemaType,
  type DHCPServerSchemaType,
  type DHCPServerNetworkSchemaType,
  type HotspotProfileSchemaType,
  type HotspotSchemaType,
  type RadiusSchemaType,
  type WirelessSchemaType,
  type SSLSchemaType,
  type WalledGardenIpSchemaType,
  type FileSchemaType,
} from './mikrotik.types'

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<{
    url: string
    method: AxiosRequestConfig['method']
    data?: AxiosRequestConfig['data']
    params?: AxiosRequestConfig['params']
  }> =>
  async ({ url, method, data, params }) => {
    try {
      const result = await customAxios({
        url: baseUrl + url,
        method,
        data,
        params,
      })
      return { data: result.data }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (method !== 'get' && error.response?.status !== 401) {
          ServiceErrorHandler(error)
        }

        if (shouldBailout(error)) {
          retry.fail(error)
        }

        //
        return {
          error: {
            status: error.response?.status,
            data: error.response?.data || error.message,
          },
        }
      }
    }
  }

const wifi = createApi({
  reducerPath: 'wifi',
  baseQuery: retry(axiosBaseQuery({ baseUrl: config.url.trinity }), {
    maxRetries: 1,
  }),
  tagTypes: [
    'WiFiConfig',
    'WiFiInform',
    'UniFiControllers',
    'UniFiLocations',
    'UniFiControllerConfig',
    'PaymentPlans',
    'GuestPayments',
    /**
     * Mikrotik tags
     */
    'MikrotikInterfaces',
    'MikrotikSystem',
    'MikrotikStatus',
    'MikrotikWlan',
    'MikrotikRadius',
    'MikrotikHotspot',
    'MikrotikNetwork',
    'MikrotikSsl',
    'MikrotikFiles',
    'MikrotikWalledGarden',
    'MikrotikHosts',
    /**
     * Heartbeat
     */
    'NetworkHeartbeat',
    'RadiusPolicyGroups',
  ],
  endpoints: (build) => ({
    getSettings: build.query<WiFiConfigType, { serial: string }>({
      query: ({ serial }) => ({
        url: `/${serial}/config`,
        method: 'get',
      }),
      providesTags: (res) => [{ type: 'WiFiConfig', id: res?.serial }],
      transformResponse: (data: WiFiConfigType) => {
        if (!data.free_questions) {
          data.free_questions = ['Email']
        }
        data.string_type = data.type.toString()
        return data
      },
    }),
    getSessions: build.query<
      ServicePaginatedResponseType<WiFiSessionType>,
      { serial: string; data: unknown }
    >({
      query: ({ serial, data }) => ({
        method: 'GET',
        url: buildUrl(`/${serial}/guests/sessions`, data),
        data,
      }),
    }),
    getImpressions: build.query<
      ServicePaginatedResponseType<WiFiImpressionsType>,
      {
        serial: string
        query: {
          sort_by?: 'asc' | 'desc'
          limit: number
          cursor: string
        }
      }
    >({
      query: ({ serial, query }) => ({
        method: 'GET',
        url: buildUrl(`/${serial}/guests/impressions`, query),
      }),
      serializeQueryArgs: ({ queryArgs }) => {
        return JSON.stringify({
          serial: queryArgs.serial,
          params: {
            ...queryArgs.query,
            cursor: '',
          },
        })
      },
      merge: (currentCache, newItems) => {
        currentCache.links = newItems.links
        currentCache.meta = newItems.meta

        newItems.data.forEach((item) => {
          const current = currentCache.data.findIndex((it) => it.id === item.id)
          if (current >= 0) {
            currentCache.data[current] = item
          } else {
            currentCache.data.push(item)
          }
        })

        currentCache.data = currentCache.data.sort(
          (a, b) =>
            parseISO(b.created_at).getTime() - parseISO(a.created_at).getTime()
        )
      },
      forceRefetch({ currentArg, previousArg }) {
        return (
          currentArg?.query.cursor === '' ||
          !isEqual(currentArg?.query, previousArg?.query)
        )
        //  return currentArg !== previousArg
      },
    }),
    updateAutoAuth: build.mutation<
      WiFiConfigType,
      {
        serial: string
        data: {
          type: string
        }
      }
    >({
      query: ({ serial, data }) => ({
        url: `/${serial}/config/auto-auto`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (res) => [{ type: 'WiFiConfig', id: res?.serial }],
      transformResponse: (data: WiFiConfigType) => {
        toast.success('Settings updated')
        return data
      },
    }),
    removeAutoAuth: build.mutation<
      WiFiConfigType,
      {
        serial: string
        data: {
          type: string
        }
      }
    >({
      query: ({ serial, data }) => ({
        url: `/${serial}/config/auto-auto/${data.type}`,
        method: 'DELETE',
        data,
      }),
      invalidatesTags: (res) => [{ type: 'WiFiConfig', id: res?.serial }],
      transformResponse: (data: WiFiConfigType) => {
        toast.success('Settings updated')
        return data
      },
    }),
    updateSettings: build.mutation<
      WiFiConfigType,
      { serial: string; data: Partial<WiFiConfigType> }
    >({
      query: ({ serial, data }) => ({
        url: `/${serial}/config`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (res) => [{ type: 'WiFiConfig', id: res?.serial }],
      transformResponse: (data: WiFiConfigType) => {
        toast.success('Settings updated')
        return data
      },
    }),
    getVendors: build.query<WifiVendorType[], unknown>({
      query: () => ({
        method: 'GET',
        url: '/vendors',
      }),
      transformResponse: (items: WifiVendorType[] = []) => {
        return items.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      },
    }),
    getInform: build.query<WiFiInformType, { serial: string }>({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/vendor`,
      }),
      providesTags: (data) => [{ type: 'WiFiInform', id: data?.id }],
    }),
    updateVendor: build.mutation<
      WiFiInformType,
      { serial: string; vendor_id: string; radius_secret?: string }
    >({
      query: (data) => ({
        method: 'PUT',
        url: `/${data.serial}/config/vendor/${data.vendor_id}`,
        data,
      }),
      invalidatesTags: (res, _1, { serial }) => [
        { type: 'WiFiInform', id: res?.id },
        { type: 'MikrotikStatus', id: serial },
      ],
      transformResponse: (data: WiFiInformType) => {
        toast.success('Vendor updated')
        return data
      },
    }),
    getUniFiControllers: build.query<UniFiControllerType[], { serial: string }>(
      {
        query: ({ serial }) => ({
          method: 'GET',
          url: `/${serial}/config/unifi`,
        }),
        providesTags: [{ type: 'UniFiControllers', id: 'LIST' }],
      }
    ),
    getUniFiControllerSite: build.query<UniFiConfigType, { serial: string }>({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/unifi/site`,
      }),
      providesTags: (item) => [{ type: 'UniFiControllerConfig', id: item?.id }],
    }),
    getUniFiControllerSites: build.query<
      { id: string; name: string }[],
      { serial: string; controllerId: string }
    >({
      query: ({ serial, controllerId }) => ({
        method: 'GET',
        url: `/${serial}/config/unifi/${controllerId}`,
      }),
      providesTags: [{ type: 'UniFiLocations', id: 'LIST' }],
      transformResponse: (data: { id: string; name: string }[]) => {
        return data.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })
      },
    }),
    getUniFiControllerSitesSsids: build.query<
      { id: string; name: string }[],
      { serial: string; controllerId: string; siteId: string }
    >({
      query: ({ serial, controllerId, siteId }) => ({
        method: 'GET',
        url: `/${serial}/config/unifi/${controllerId}/${siteId}`,
      }),
    }),
    updateUniFiControllerSite: build.mutation<
      UniFiConfigType,
      {
        serial: string
        data: {
          controller_id: string
          site_id: string
          timeout: number
          multi_site_ssid: string
        }
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/unifi/site`,
        data,
      }),

      invalidatesTags: (item) => [
        { type: 'UniFiControllerConfig', id: item?.id },
      ],
      transformResponse: (data: UniFiConfigType) => {
        toast.success('UniFi details updated')
        return data
      },
    }),
    createUniFiController: build.mutation<
      UniFiControllerType,
      {
        serial: string
        data: { hostname: string; username: string; password: string }
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/unifi`,
        data,
      }),
      invalidatesTags: [{ type: 'UniFiControllers', id: 'LIST' }],
      transformResponse: (data: UniFiControllerType) => {
        toast.success('UniFi controller created')
        return data
      },
    }),

    /**
     *
     */
    getPaymentPlans: build.query<
      PaymentPlanType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/plans`,
      }),
      providesTags: (res) => [
        { type: 'PaymentPlans', id: 'LIST' },
        ...(res ?? []).map((item) => ({
          type: 'PaymentPlans' as const,
          id: item.id,
        })),
      ],
      transformResponse: (data: PaymentPlanType[]) => {
        return [...data].sort((a, b) => {
          return a.amount - b.amount
        })
      },
    }),
    createPaymentPlan: build.mutation<
      string[],
      {
        serial: string
        data: Partial<PaymentPlanType>
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/plans`,
        data,
      }),
      invalidatesTags: [{ type: 'PaymentPlans', id: 'LIST' }],
      transformResponse: (data: string[]) => {
        toast.success('Payment plan created')
        return data
      },
    }),
    updatePaymentPlan: build.mutation<
      PaymentPlanType,
      {
        serial: string
        data: PaymentPlanType
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/plans/${data.id}`,
        data,
      }),
      invalidatesTags: (res) => [{ type: 'PaymentPlans', id: res?.id }],
      transformResponse: (data: PaymentPlanType) => {
        toast.success('Payment plan updated')
        return data
      },
    }),

    /**
     *
     *
     */
    removePaymentPlans: build.mutation<
      unknown,
      {
        serial: string
        plan_id: string
      }
    >({
      query: ({ serial, plan_id }) => ({
        method: 'DELETE',
        url: `/${serial}/config/plans/${plan_id}`,
      }),
      invalidatesTags: () => [
        {
          id: 'LIST',
          type: 'PaymentPlans',
        },
      ],
      transformResponse: (data: PaymentPlanType) => {
        toast.success('Payment plan deleted')
        return data
      },
    }),

    /**
     *
     */

    getGuestPayments: build.query<
      ServicePaginatedResponseType<GuestPaymentType>,
      {
        serial: string
        data: unknown
      }
    >({
      query: ({ serial, data }) => ({
        method: 'GET',
        url: buildUrl(`/${serial}/guests/payments`, data),
        data,
      }),
      providesTags: [{ type: 'GuestPayments', id: 'LIST' }],
    }),
    createGuestPayments: build.mutation<
      unknown,
      {
        serial: string
        data: PaymentUpsertType
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/guests/payments`,
        data,
      }),
      invalidatesTags: [{ type: 'GuestPayments', id: 'LIST' }],
      transformResponse: (data: unknown) => {
        toast.success('Guest payment created')
        return data
      },
    }),
    updateGuestPayments: build.mutation<
      unknown,
      {
        serial: string
        data: PaymentUpsertType
      }
    >({
      query: ({ serial, data }) => ({
        method: 'PUT',
        url: `/${serial}/guests/payments/${data.id}`,
        data,
      }),
      invalidatesTags: [{ type: 'GuestPayments', id: 'LIST' }],
      transformResponse: (data: unknown) => {
        toast.success('Guest payment updated')
        return data
      },
    }),
    removeGuestPaymentDevice: build.mutation<
      unknown,
      {
        serial: string
        payment_id: string
        device_id: string
      }
    >({
      query: ({ serial, payment_id, device_id }) => ({
        method: 'DELETE',
        url: `/${serial}/guests/payments/${payment_id}/${device_id}`,
      }),
      invalidatesTags: [{ type: 'GuestPayments', id: 'LIST' }],
      transformResponse: (data: unknown) => {
        toast.success('Guest payment device deleted')
        return data
      },
    }),
    getWalledGarden: build.query<
      string[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/walled-garden`,
      }),
      providesTags: (_1, _2, { serial: id }) => [{ type: 'WiFiConfig', id }],
    }),
    getUserSessions: build.query<
      ServicePaginatedResponseType<WiFiSessionType>,
      {
        orgId: string
        cursor: string
        limit: number
        customerId: string
      }
    >({
      query: ({ orgId, customerId, cursor, limit }) => ({
        method: 'GET',
        url: buildUrl(`/organisations/${orgId}/guest/${customerId}/sessions`, {
          cursor,
          limit,
        }),
      }),
    }),
    getUserImpressions: build.query<
      ServicePaginatedResponseType<WiFiImpressionsType>,
      {
        orgId: string
        cursor: string
        limit: number
        customerId: string
      }
    >({
      query: ({ orgId, customerId, cursor, limit }) => ({
        method: 'GET',
        url: buildUrl(
          `/organisations/${orgId}/guest/${customerId}/impressions`,
          {
            cursor,
            limit,
          }
        ),
      }),
    }),
    getUserPayments: build.query<
      ServicePaginatedResponseType<GuestPaymentType>,
      {
        orgId: string
        cursor: string
        limit: number
        customerId: string
      }
    >({
      query: ({ orgId, customerId, cursor, limit }) => ({
        method: 'GET',
        url: buildUrl(`/organisations/${orgId}/guest/${customerId}/payments`, {
          cursor,
          limit,
        }),
      }),
    }),

    /**
     * MIKROTIK API START
     */
    getInterfaces: build.query<
      InterfaceSchemaType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/interfaces`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikInterfaces', id: serial },
      ],
    }),
    getSystem: build.query<
      SystemSchemaType,
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/system`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikSystem', id: serial },
      ],
    }),
    updateSystem: build.mutation<
      SystemSchemaType,
      {
        serial: string
        data: {
          apply_port_config: boolean
        }
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/system`,
        data,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikSystem', id: serial },
      ],
    }),
    getMikrotikStatus: build.query<
      {
        can_use_api: boolean
        can_use_ssl: boolean
        can_login_using_api: boolean
        resolved_ddns_address: string
        version: number
      },
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/status`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikStatus', id: serial },
      ],
    }),
    getMikrotikWlan: build.query<
      WirelessSchemaType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/wlan`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikWlan', id: serial },
      ],
    }),
    setSSID: build.mutation<
      unknown,
      {
        serial: string
        data: { ssid: string }
      }
    >({
      extraOptions: { maxRetries: 0 },
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/wlan`,
        data,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikWlan', id: serial },
      ],
    }),
    getMikrotikRadius: build.query<
      RadiusSchemaType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/radius`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikRadius', id: serial },
      ],
    }),
    setMikrotikRadius: build.mutation<
      RadiusSchemaType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/radius`,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikRadius', id: serial },
      ],
    }),
    getMikrotikHotspot: build.query<
      {
        hotspot: HotspotSchemaType
        profiles: HotspotProfileSchemaType
      },
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/hotspot`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikHotspot', id: serial },
      ],
    }),
    setMikrotikHotspot: build.mutation<
      {
        hotspot: HotspotSchemaType
        profiles: HotspotProfileSchemaType
      },
      {
        serial: string
        data: {
          interface: string
        }
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/hotspot`,
        data,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikHotspot', id: serial },
      ],
    }),
    getMikrotikNetwork: build.query<
      {
        addresses: AddressSchemaType
        dhcp_server: DHCPServerSchemaType
        dhcp_server_network: DHCPServerNetworkSchemaType
        dns: DNSSchemaType
        firewall: FirewallSchemaType[]
        pool: PoolSchemaType
      },
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/network`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikNetwork', id: serial },
      ],
    }),
    updateMikrotikNetwork: build.mutation<
      {
        addresses: AddressSchemaType
        dhcp_server: DHCPServerSchemaType
        dhcp_server_network: DHCPServerNetworkSchemaType
        dns: DNSSchemaType
        firewall: FirewallSchemaType[]
        pool: PoolSchemaType
      },
      {
        serial: string
        data: {
          interface: string
        }
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/network`,
        data,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikNetwork', id: serial },
      ],
    }),
    getMikrotikSsl: build.query<
      SSLSchemaType,
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/ssl`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikSsl', id: serial },
      ],
    }),
    createMikrotikSsl: build.mutation<
      { progress: string; '.section': string }[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/ssl`,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikSsl', id: serial },
      ],
    }),
    getHotspot: build.query<
      {
        hotspot: HotspotSchemaType
        profiles: HotspotProfileSchemaType
      },
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/hotspot`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikHotspot', id: serial },
      ],
    }),
    rebootMikrotik: build.mutation<
      unknown,
      {
        serial: string
      }
    >({
      extraOptions: { maxRetries: 0 },
      query: ({ serial }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/reboot`,
      }),
    }),

    provisionMikrotik: build.mutation<
      unknown,
      {
        serial: string
        data: { interface: string }
      }
    >({
      extraOptions: { maxRetries: 0 },
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/provision`,
        data,
      }),
    }),
    getMikrotikWalledGarded: build.query<
      {
        walled_garden: WalledGardenIpSchemaType[]
        ips: WalledGardenIpSchemaType[]
      },
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/walled-garden`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikWalledGarden', id: serial },
      ],
    }),
    updateMikrotikWalledGarded: build.mutation<
      unknown,
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/walled-garden`,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikWalledGarden', id: serial },
      ],
    }),

    getMikrotikFiles: build.query<
      FileSchemaType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/files`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikFiles', id: serial },
      ],
    }),
    updateMikrotikFiles: build.mutation<
      unknown,
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/files`,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikFiles', id: serial },
      ],
    }),
    changeHeartbeat: build.mutation<
      {
        serial: 'QBRNYCIEGJSA'
      },
      {
        serial: string
        status:
          | 'OFFLINE'
          | 'ONLINE'
          | 'IP_UPDATE'
          | 'PAUSED'
          | 'MISSED'
          | 'REQUIRES_UPDATE'
      }
    >({
      query: ({ serial, status }) => ({
        method: 'PUT',
        url: `/${serial}/config/hardware/health`,
        data: {
          status,
        },
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'NetworkHeartbeat', id: serial },
      ],
    }),
    getHeartbeat: build.query<
      {
        created_at: string
        id: string
        inform_id: string
        ip: string
        serial: 'QBRNYCIEGJSA'
        status:
          | 'OFFLINE'
          | 'ONLINE'
          | 'IP_UPDATE'
          | 'PAUSED'
          | 'MISSED'
          | 'REQUIRES_UPDATE'
      }[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/health`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'NetworkHeartbeat', id: serial },
      ],
    }),
    getMikrotikHosts: build.query<
      {
        id: string
        DHCP: string
        address: string
        authorized: string
        bypassed: string
        'bytes-in': string
        'bytes-out': string
        'found-by': string
        'host-dead-time': string
        'idle-time': string
        'idle-timeout': string
        'mac-address': string
        'packets-in': string
        'packets-out': string
        server: string
        'to-address': string
        uptime: string
      }[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/mikrotik/hotspot/hosts`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikHosts', id: serial },
      ],
    }),
    removeMikrotikHosts: build.mutation<
      {
        id: string
        DHCP: string
        address: string
        authorized: string
        bypassed: string
        'bytes-in': string
        'bytes-out': string
        'found-by': string
        'host-dead-time': string
        'idle-time': string
        'idle-timeout': string
        'mac-address': string
        'packets-in': string
        'packets-out': string
        server: string
        'to-address': string
        uptime: string
      }[],
      {
        serial: string
        remove_macs: string[]
      }
    >({
      query: ({ serial, remove_macs }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/mikrotik/hotspot/hosts`,
        data: {
          remove_macs,
        },
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'MikrotikHosts', id: serial },
      ],
    }),
    getPolicyGroups: build.query<
      RadiusGroupPolicyType[],
      {
        serial: string
      }
    >({
      query: ({ serial }) => ({
        method: 'GET',
        url: `/${serial}/config/hardware/group`,
      }),
      providesTags: (_0, _1, { serial }) => [
        { type: 'RadiusPolicyGroups', id: serial },
      ],
    }),
    updatePolicyGroup: build.mutation<
      RadiusGroupPolicyType,
      {
        serial: string
        data: RadiusGroupPolicyType
      }
    >({
      query: ({ serial, data }) => ({
        method: 'PUT',
        url: `/${serial}/config/hardware/group/${data.id}`,
        data,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'RadiusPolicyGroups', id: serial },
      ],
    }),
    //config_id, created_at, payment_plan_id
    createPolicyGroup: build.mutation<
      RadiusGroupPolicyType,
      {
        serial: string
        data: Omit<RadiusGroupPolicyType, 'id' | 'config_id' | 'created_at'>
      }
    >({
      query: ({ serial, data }) => ({
        method: 'POST',
        url: `/${serial}/config/hardware/group`,
        data,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'RadiusPolicyGroups', id: serial },
      ],
    }),
    deletePolicyGroup: build.mutation<
      RadiusGroupPolicyType,
      {
        serial: string
        data: RadiusGroupPolicyType
      }
    >({
      query: ({ serial, data }) => ({
        method: 'DELETE',
        url: `/${serial}/config/hardware/group/${data.id}`,
      }),
      invalidatesTags: (_0, _1, { serial }) => [
        { type: 'RadiusPolicyGroups', id: serial },
      ],
    }),
    /**
     * MIKROTIK API END
     */
  }),
})

export default wifi
