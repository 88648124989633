import { IconButton, Icon, Box, HStack, Text } from '@chakra-ui/react'
import { InformationCircleIcon } from '@heroicons/react/16/solid'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format, roundToNearestMinutes } from 'date-fns'
import { FunctionComponent } from 'react'
import { InboxThreadEventEventableNoteType } from '@/state/entities/inbox/inbox.types'
import { ChatAvatar, ThreadPopover } from './common'

const ThreadNoteBox: FunctionComponent<{
  event: InboxThreadEventEventableNoteType
}> = ({ event }) => {
  return (
    <HStack align="end" justify="end" ml={24} role="group">
      <Box>
        <Box
          py={1}
          px={5}
          bg="yellow.100"
          borderRadius="2xl"
          id="content"
          position="relative"
        >
          <ThreadPopover event={event}>
            <IconButton
              opacity={0}
              left={0}
              _groupHover={{
                opacity: 1,
                left: '-32px',
              }}
              position="absolute"
              transition="ease-in-out left  250ms, ease-in opacity 250ms"
              variant="ghost"
              isRound
              icon={<Icon as={InformationCircleIcon} />}
              aria-label="info"
            />
          </ThreadPopover>
          <Text
            color="black"
            dangerouslySetInnerHTML={{ __html: event.description }}
          />
        </Box>
        <Text variant="desc" textAlign="right" id="time">
          {format(
            roundToNearestMinutes(event.created_at, {
              nearestTo: 10,
            }),
            TimeFormats.FourHourApple
          )}
        </Text>
      </Box>
      <Box boxSize="32px" minW="32px">
        <ChatAvatar
          id="avatar"
          email={event.user_email}
          name={event.user_name}
        />
      </Box>
    </HStack>
  )
}

export default ThreadNoteBox
