import type { DeepPartial } from '@/config'
import type { Config } from './config.type'
import domain from './domain'

const NGROK_DOMAIN = 'https://34aa-84-69-246-4.eu.ngrok.io'

const isLocal =
  window.location.hostname.includes('localhost') ||
  window.location.hostname.includes('ngrok.io')
const localAuthDomain = window.location.hostname.includes('ngrok.io')
  ? NGROK_DOMAIN
  : window.location.origin
//commit
const useProd = false
const config: DeepPartial<Config> = {
  appName: 'Stampede Product',
  url: {
    selfservice: `https://api-selfservice.stg.${domain}`,
    api: `https://api${useProd ? '' : '.stage'}.${domain}`,
    // api: `http://localhost:8080`,
    connect: isLocal
      ? localAuthDomain
      : `https://product${useProd ? '' : '.stage'}.${domain}`,
    accounts: `https://accounts${useProd ? '' : '.stage'}.${domain}`,
    nearly: `https://wifi.stage.${domain}`,
    mailchimp: `https://mailchimp.stage.${domain}`,
    reviews: `https://reviews${useProd ? '' : '.stage'}.${domain}`,
    calculator: '//localhost:3006/pricing/calc',
    service: `https://service${useProd ? '.prd' : '.stg'}.${domain}`,
    cypher: `https://cypher${useProd ? '.prd' : '.stg'}.${domain}`,
    forms: `https://forms${useProd ? '' : '.stage'}.stampede.ai`,
    urlGenerator: `https://url-generator.${useProd ? 'prd' : 'stg'}.${domain}`,
    bookingIntegration: `https://bookings-intergrations.${
      useProd ? 'prd' : 'stg'
    }.${domain}`,
    bookings: `https://api-bookings${useProd ? '.prd' : '.stg'}.${domain}`,
    booking_url: `https://booking${useProd ? '' : '.stage'}.stampede.ai`,
    gifting: `https://gifting.stage.${domain}`,
    reporting: `https://reporting.stg.${domain}`,
    inbox_email: `@${useProd ? '' : 'stage.'}stmpd-mail.com`,
    inbox: `https://inbox${useProd ? '.prd' : '.stg'}.${domain}`,
    morpheus: `https://morpheus${useProd ? '.prd' : '.stg'}.${domain}`,
    //morpheus: `http://localhost:8080`,
    //zion: `http://localhost:8080`,
    zion: `https://zion${useProd ? '.prd' : '.stg'}.${domain}`,
    // agent_smith: `http://localhost:8081`,
    agent_smith: `https://agent-smith${useProd ? '.prd' : '.stg'}.${domain}`,
    loyalty: `https://loyalty${useProd ? '.prd' : '.stg'}.${domain}`,
    // loyalty: `http://localhost:8080`,
    trinity: `https://trinity${useProd ? '.prd' : '.stg'}.${domain}`,
    auth: `https://auth${useProd ? '' : '.stage'}.${domain}`,
    integrations: `https://bookings-intergrations${
      useProd ? 'prd' : '.stg'
    }.${domain}`,
    reports: `https://reports-api.stg.${domain}`,
    sites: `https://sites.stage.${domain}`,
  },
  auth: {
    clientId: isLocal ? 'connect_react_stage' : `${domain}.connect_stage`,
    redirectUri: `${localAuthDomain}/code`,
  },
  enableWhyDidYouUpdate: false,
  chargebee: {
    site: 'stampede-test',
    domain: 'https://stampede-test.chargebee.com',
  },
}

export default config
