import { cva, type VariantProps } from 'class-variance-authority'
import { ClassValue, cn, loadingVariants, Spinner } from 'tailwind-ui'
import { forwardRef } from 'react'
import { Icn, SvgIcon } from '../icon'

export interface SIconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof iconButtonVariants> {
  isLoading?: boolean
  icon?: SvgIcon
  iconProps?: React.SVGAttributes<SVGElement>
  isRound?: boolean
  isDarkBg?: boolean
  brandKit?: boolean
  isActive?: boolean
  children?: React.ReactNode
  classNames?: {
    icon?: ClassValue
  }
  bubble?: number
}

export const Bubble = ({ count }: { count: number }) => {
  if (!count) return null

  return (
    <div className="absolute -top-1 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-red-500 text-[10px] font-medium text-white">
      {count > 99 ? '99+' : count}
    </div>
  )
}

export const iconButtonVariants = cva(
  `relative fill-gray-700 flex-none dark:fill-gray-50 inline-flex items-center justify-center rounded-md font-medium focus-visible:ring-primary-500 
  focus:outline-none focus-visible:ring  transition-colors duration-75 disabled:cursor-not-allowed`,
  {
    variants: {
      variant: {
        primary: [
          'bg-primary-500 text-white',

          'hover:bg-primary-600 hover:text-white',
          'active:bg-primary-700',
          'disabled:bg-primary-700',
        ],
        outline: [
          'text-primary-500',
          'border-primary-500 border',
          'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
        ],
        ghost: [
          'text-primary-500',
          'shadow-none',
          'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
        ],
        light: [
          'bg-white text-gray-700',

          'hover:text-dark hover:bg-gray-100',
          'active:bg-white/80 disabled:bg-gray-200',
        ],
        dark: [
          'bg-gray-900 text-white',

          'hover:bg-gray-800 active:bg-gray-700 disabled:bg-gray-700',
        ],
        default: [
          'bg-black/10 border border-black/5 text-black/80 backdrop-blur-lg',

          'hover:text-black hover:bg-black/15',
          'active:bg-white/80 active:text-white disabled:bg-black/20',
          'dark:bg-white/10 dark:text-white/70 dark:hover:text-white/90 dark:border-white/5',

          'dark:hover:bg-white/15 dark:active:bg-white/20 dark:disabled:bg-white/20',
        ],
        ghost_default: [
          'text-black/90 dark:text-white/90',

          'hover:text-black hover:bg-black/10',
          'active:bg-white/80 disabled:bg-black/20',
          'dark:hover:bg-white/10 dark:active:bg-white/5 dark:disabled:bg-white/5 !min-h-[20px]',
        ],
        link: [
          'text-gray-500 dark:text-gray-400',
          '!shadow-none',
          '!px-0 !py-0',
          'hover:text-dark',
        ],
        warning: [
          'bg-red-600 text-white',

          'hover:bg-red-700 hover:text-white',
          'active:bg-red-800',
          'disabled:bg-red-300',
        ],
      },

      brandKit: {
        true: '!rounded-button !bg-brand !text-brand-invert  hover:opacity-90 active:opacity-90 hover:bg-brand active:bg-brand',
        false: 'rounded-md',
      },
      size: {
        xs: 'h-[24px] w-[24px] p-0',
        sm: 'h-[34px] w-[34px] p-0.5',
        base: 'h-[40px] w-[40px] p-1',
        lg: 'h-[46px] w-[46px] p-2',
      },
      isActive: {
        false: null,
        true: null,
      },
      isDarkBg: {
        false: null,
        true: 'text-white hover:text-black',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'base',
    },
    compoundVariants: [
      {
        brandKit: true,
        variant: ['ghost_default', 'link'],
        class: '!bg-transparent !text-interface-500 !hover:text-interface-800',
      },
      {
        isActive: true,
        variant: ['default'],
        class: 'dark:bg-white/20 bg-black/20',
      },
      {
        isActive: true,
        variant: ['primary'],
        class: 'bg-primary-800',
      },
      {
        isActive: true,
        variant: ['ghost'],
        class: 'bg-primary-100',
      },
      {
        isActive: true,
        variant: ['light'],
        class: 'bg-gray-100 dark:bg-gray-300',
      },
      {
        isActive: true,
        variant: ['dark'],
        class: 'bg-gray-700',
      },
      {
        isActive: true,
        variant: ['ghost_default'],
        class:
          'text-primary-500 bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-600',
      },
      {
        isActive: true,

        variant: ['outline'],
        class: 'bg-primary-100',
      },
      {
        isActive: true,

        variant: ['outline'],
        class: 'bg-gray-800',
      },
      {
        variant: ['outline', 'ghost'],
        class: 'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800',
      },
    ],
  }
)

export const SIconButton = forwardRef<HTMLButtonElement, SIconButtonProps>(
  (
    {
      className,
      disabled: buttonDisabled,
      isLoading,
      variant = 'default',
      size = 'base',
      isDarkBg = false,
      icon: Icon,
      classNames,
      isRound = true,
      brandKit = false,
      isActive,
      iconProps,
      bubble,
      ...rest
    },
    ref
  ) => {
    const disabled = isLoading || buttonDisabled
    const v = variant ?? 'default'
    return (
      <button
        className={cn(
          iconButtonVariants({
            variant,
            size,
            className,
            brandKit,
            isActive,
            isDarkBg,
          }),
          isLoading &&
            ' text-transparent transition-none hover:text-transparent disabled:cursor-wait',
          isRound && 'rounded-full',
          isDarkBg &&
            ['outline', 'ghost', 'default'].includes(v) &&
            'hover:bg-gray-900 active:bg-gray-800 disabled:bg-gray-800  '
        )}
        disabled={disabled}
        ref={ref}
        type="button"
        {...rest}
      >
        {isLoading ? (
          <Spinner
            variant={variant}
            brandKit={brandKit}
            className={cn('', loadingVariants({ variant, brandKit, size }))}
          />
        ) : Icon ? (
          <>
            {bubble ? <Bubble count={bubble} /> : null}
            <Icn
              icon={Icon}
              size={size}
              className={cn(classNames?.icon, {
                'opacity-0': isLoading,
              })}
              {...iconProps}
            />
          </>
        ) : (
          <div />
        )}
      </button>
    )
  }
)

SIconButton.displayName = 'SIconButton'
