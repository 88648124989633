import {
  FormControl,
  FormLabel,
  HStack,
  Switch,
  useBoolean,
  useBreakpointValue,
} from '@chakra-ui/react'
import { CalendarDaysIcon, ChevronDownIcon } from '@heroicons/react/16/solid'
import {
  addMonths,
  endOfDay,
  endOfMonth,
  format,
  startOfMonth,
  subYears,
} from 'date-fns'
import { useCallback, useMemo, useState } from 'react'
import { Modal, SButton } from 'tailwind-ui'
import { useGlobalQuery } from '@/state/global/global.hooks'
import { TimeFormats } from './utilities/formats'
import MonthPicker from './utilities/month.picker'
import DateLitePicker from './utilities/picker'

function DatePickerSelector() {
  const [query, setQuery] = useGlobalQuery()

  const [isOpen, setIsOpen] = useState(false)
  const isMobileFormat = useBreakpointValue({
    base: TimeFormats.StandardDateMonthYearSmall,
    lg: TimeFormats.StandardDateMonthYear,
  })

  return (
    <>
      <Modal
        title="Select date range"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        size="xl"
        classNames={{
          body: 'p-0',
        }}
      >
        <DateLitePicker
          dates={[new Date(query.startDate), new Date(query.endDate)]}
          onChange={([startDate, endDate]) => {
            setIsOpen(false)
            setQuery({
              startDate: startDate.getTime(),
              endDate: endOfDay(endDate).getTime(),
            })
          }}
        />
      </Modal>
      <SButton
        size="lg"
        leftIcon={CalendarDaysIcon}
        onClick={() => {
          setIsOpen(true)
        }}
        rightIcon={ChevronDownIcon}
        variant="ghost_default"
      >
        {format(new Date(query.startDate), isMobileFormat)} {' — '}
        {format(query.endDate, isMobileFormat)}
      </SButton>
    </>
  )
}

interface PassedProps {
  dates: [Date, Date]
  onChange: (dates: [Date, Date]) => void
  allTime?: boolean
  isExclusive?: boolean
  minDate?: Date
}

export function DatePickerSelectorControlled({
  dates,
  onChange,
  minDate = null,
}: PassedProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [start, end] = dates
  return (
    <>
      <Modal
        title="Select date range"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
        size="xl"
        classNames={{
          body: 'p-0',
        }}
      >
        <DateLitePicker
          minDate={minDate}
          dates={dates}
          onChange={([startDate, endDate]) => {
            setIsOpen(false)
            onChange([startDate, endOfDay(endDate)])
          }}
        />
      </Modal>
      <SButton
        leftIcon={CalendarDaysIcon}
        onClick={() => {
          setIsOpen(true)
        }}
        rightIcon={ChevronDownIcon}
        variant="ghost_default"
        className="min-h-full !h-full"
      >
        {format(start, TimeFormats.StandardDateMonthYear)} {' — '}
        {format(end, TimeFormats.StandardDateMonthYear)}
      </SButton>
    </>
  )
}

export function MonthPickerSelector({
  dates,
  onChange,
  allTime = false,
  isExclusive = false,
}: PassedProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectFullYear, setSelectFullYear] = useBoolean(false)
  const [start, end] = dates
  const isAll = useMemo(() => {
    if (!allTime) return false
    const allStart = subYears(startOfMonth(new Date()), 7)
    return allStart.getTime() === start.getTime()
  }, [allTime, start])

  const formatEnd = useCallback(
    (startDate: Date, endDate: Date) => {
      if (selectFullYear) return endDate
      return isExclusive
        ? startOfMonth(addMonths(startDate, 1))
        : endOfMonth(startDate)
    },
    [isExclusive, selectFullYear]
  )

  return (
    <>
      <Modal
        title="Select year/month"
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false)
        }}
      >
        <div className="flex flex-col gap-4">
          <MonthPicker
            dates={dates}
            onChange={([startDate, endDate]) => {
              setIsOpen(false)
              onChange([startDate, formatEnd(startDate, endDate)])
            }}
          />
          <FormControl as={HStack} justify="space-between">
            <FormLabel mb={0}>Select full year</FormLabel>
            <Switch
              isChecked={selectFullYear}
              onChange={setSelectFullYear.toggle}
            />
          </FormControl>
          {allTime ? (
            <div className="self-center">
              <SButton
                isActive={isAll}
                leftIcon={CalendarDaysIcon}
                onClick={() => {
                  setIsOpen(false)
                  onChange([
                    subYears(startOfMonth(new Date()), 7),
                    startOfMonth(new Date()),
                  ])
                }}
              >
                All-time
              </SButton>
            </div>
          ) : null}
        </div>
      </Modal>
      <SButton
        leftIcon={CalendarDaysIcon}
        onClick={() => {
          setIsOpen(true)
        }}
        rightIcon={ChevronDownIcon}
        variant="ghost_default"
      >
        {!selectFullYear && !isAll && format(start, TimeFormats.DateYear)}
        {selectFullYear && !isAll ? (
          <>
            {format(start, TimeFormats.DateYear)} {' — '}
            {format(end, TimeFormats.DateYear)}
          </>
        ) : null}
        {isAll ? <>All-time</> : null}
      </SButton>
    </>
  )
}

export default DatePickerSelector
