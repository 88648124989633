import { createApi, retry } from '@reduxjs/toolkit/query/react'
import { add, differenceInSeconds, format, parseISO } from 'date-fns'
import { toZonedTime } from 'date-fns-tz'
import { isEmpty } from 'lodash'
import type { Stat } from '@/pages/[org_id]/venues/[serial]/bookings/reporting/stats/types'
import type { ServiceCursorPaginatedResponseType } from '@/connect-types/backend/service'
import config from '@/config'
import { buildUrl } from '@/utils/common'
import { axiosBaseQuery } from '../helpers'
import type {
  ActivityLog,
  ActivityLogs,
  Area,
  Availability,
  Booking,
  BookingAndDuration,
  BookingType,
  CloseOut,
  Combination,
  CoverLimitDaysType,
  GoogleConfig,
  Internal_Booking_Note,
  MorpheusVenueWithinABookingContext,
  SpecialDate,
  SwapManual,
  Table,
  ZionConfigWithBranding,
  ZionVenueConfig,
  TimeFrame,
  ReportingResponse,
  ZionEvePromptReply,
  InvitationStatus,
  BDAScorePayload,
  ZionDynamicConfig,
  AutomationConfig,
  BookingAutomationStat,
  ManualPayment,
  FloorPlan,
  FloorPlanTable,
} from './types'
import { Any } from '@react-spring/web'
import { CreateManualPaymentPayload } from '@/pages/[org_id]/venues/[serial]/bookings/day-planner/side-nav/manual_payment_form/zod'

const service = 'zion'

const zionApi = createApi({
  reducerPath: service,
  refetchOnFocus: true,
  refetchOnReconnect: true,
  keepUnusedDataFor: 60 * 60 * 12,
  tagTypes: [
    'booking-config',
    'special-dates',
    'booking-types',
    'areas',
    'combinations',
    'availability',
    'bookings',
    'daily-stats',
    'notes',
    'close-outs',
    'tables',
    'cover-limits',
    'activity',
    'open-close',
    'google-config',
    'reporting',
    'note',
    'bda-score',
    'automations',
    'cover-counts',
    'manual-payments',
    'floorplans',
    'floorplan',
  ],

  baseQuery: retry(
    axiosBaseQuery({
      baseUrl: config.url.zion,
      service,
    }),
    {
      maxRetries: 2,
    }
  ),

  endpoints: (build) => ({
    getBookingsSearch: build.query<
      ServiceCursorPaginatedResponseType<Booking>,
      {
        serial: string
        org_id: string
        limit: number
        search: string
        cursor: string
      }
    >({
      query: ({ org_id, search, serial, cursor, limit }) => {
        return {
          url: buildUrl(`/${org_id}/${serial}/bookings/list`, {
            search,
            cursor,
            limit,
          }),
          method: 'GET',
        }
      },
      transformResponse: (
        items: ServiceCursorPaginatedResponseType<Booking>
      ) => {
        /**
         *  @todo - do this on backend
         */

        if ('data' in items) {
          //@ts-ignore
          items.data = items.data.map((item) => ({
            ...item,
            //@ts-ignore
            allergens: item.allergens?.length ? item.allergens : [],
            booking_tables: item.booking_tables.map((item) => item.table_id),
            duration: differenceInSeconds(
              new Date(item.end_time),
              new Date(item.start_time)
            ),
            startDate: new Date(item.start_time),
            endDate: new Date(item.end_time),
          }))

          return items
        }

        return items
      },
    }),

    getBookingsActivity: build.query<
      ServiceCursorPaginatedResponseType<ActivityLog>,
      {
        serial: string
        org_id: string
        limit: number
        search: string
        cursor: string
        oauth_id: string
      }
    >({
      query: ({ org_id, search, serial, cursor, limit, oauth_id }) => {
        return {
          url: buildUrl(`/${org_id}/${serial}/activity-log`, {
            search,
            cursor,
            limit,
            oauth_id,
          }),
          method: 'GET',
        }
      },
    }),

    getCoverLimits: build.query<
      CoverLimitDaysType,
      {
        org_id: string
        serial: string
        config_id: string
        other_id?: string
        type: 'base' | 'special-dates' | 'booking-types'
      }
    >({
      query: ({ org_id, config_id, serial, type, other_id }) => {
        let url = `/${org_id}/${serial}/config/${config_id}/cover-limits`
        if (type === 'booking-types') {
          url = `/${org_id}/${serial}/config/${config_id}/booking-types/${other_id}/cover-limits`
        }
        if (type === 'special-dates') {
          url = `/${org_id}/${serial}/config/${config_id}/special-dates/${other_id}/cover-limits`
        }

        return {
          url,
          method: 'GET',
        }
      },
      providesTags: (_, _2, _meta) => [
        {
          type: 'cover-limits',
          id: `${_meta?.type}_${_meta?.config_id}_${_meta?.other_id}`,
        },
      ],
      transformResponse: (items: CoverLimitDaysType) => {
        Object.entries(items).forEach(([day, item]) => {
          items[day].intervals = item.intervals.map((fullDay) => {
            return {
              ...fullDay,
              open_time: toZonedTime(new Date(fullDay.open_time), 'UTC'),
              close_time: toZonedTime(new Date(fullDay.close_time), 'UTC'),
            }
          })
        })

        console.log({ items }, 'cover-limits')

        return items
      },
    }),
    updateCoverLimits: build.mutation<
      CoverLimitDaysType,
      {
        org_id: string
        serial: string
        config_id: string
        other_id?: string
        type: 'base' | 'special-dates' | 'booking-types'
        data: CoverLimitDaysType
      }
    >({
      query: ({ org_id, config_id, serial, type, other_id, data }) => {
        let url = `/${org_id}/${serial}/config/${config_id}/cover-limits`
        if (type === 'booking-types') {
          url = `/${org_id}/${serial}/config/${config_id}/booking-types/${other_id}/cover-limits`
        }
        if (type === 'special-dates') {
          url = `/${org_id}/${serial}/config/${config_id}/special-dates/${other_id}/cover-limits`
        }

        return {
          url,
          method: 'PUT',
          data,
        }
      },
      invalidatesTags: (_, _2, _meta) => [
        {
          type: 'cover-limits',
          id: `${_meta?.type}_${_meta?.config_id}_${_meta?.other_id}`,
        },
      ],
    }),

    getConfigs: build.query<ZionConfigWithBranding[], { org_id: string }>({
      query: ({ org_id }) => ({
        url: `/${org_id}/config`,
        method: 'GET',
      }),
    }),

    getConfig: build.query<ZionVenueConfig, { org_id: string; serial: string }>(
      {
        query: ({ org_id, serial }) => ({
          url: `/${org_id}/${serial}/config/serial`,
          method: 'GET',
        }),
        providesTags: (res) => {
          return [{ id: res?.serial, type: 'booking-config' }]
        },
      }
    ),
    getDynamicConfig: build.query<
      ZionDynamicConfig,
      {
        org_id: string
        serial: string
        date: string
        party_size: number
        booking_type_id?: string
      }
    >({
      query: ({ org_id, serial, date, party_size, booking_type_id }) => ({
        url: `/public/${org_id}/${serial}/config?date=${date}&party_size=${party_size}${booking_type_id ? `&booking_type_id=${booking_type_id}` : ''}`,
        method: 'GET',
      }),
      providesTags: (res) => {
        return [{ id: res?.serial, type: 'booking-config' }]
      },
    }),
    getGoogleConfig: build.query<
      GoogleConfig,
      { org_id: string; serial: string }
    >({
      query: ({ org_id, serial }) => ({
        url: `/${org_id}/${serial}/google/config`,
        method: 'GET',
      }),
      providesTags: (res) => {
        return [{ id: res?.serial, type: 'google-config' }]
      },
    }),

    updateGoogleConfig: build.mutation<
      GoogleConfig,
      {
        org_id: string
        serial: string
        data: GoogleConfig
      }
    >({
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/google/config`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (res) => {
        return [{ id: res?.id, type: 'google-config' }]
      },
    }),
    getBookingTypes: build.query<
      BookingType[],
      { org_id: string; serial: string; config_id: string }
    >({
      query: ({ org_id, serial, config_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/booking-types`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'booking-types',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'booking-types', id: 'LIST' },
            ]
          : [{ type: 'booking-types', id: 'LIST' }],
    }),
    createBookingType: build.mutation<
      BookingType,
      { org_id: string; serial: string; config_id: string; data: BookingType }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/booking-types`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'booking-types', id: 'LIST' }],
    }),
    updateBookingType: build.mutation<
      BookingType,
      {
        org_id: string
        serial: string
        config_id: string
        data: BookingType
        booking_type_id: string
      }
    >({
      query: ({ org_id, serial, config_id, data, booking_type_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/booking-types/${booking_type_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [{ type: 'booking-types', id: 'LIST' }],
    }),
    updateBookingTypePositions: build.mutation<
      BookingType[],
      { org_id: string; serial: string; config_id: string; data: BookingType[] }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/booking-types/positions`,
        method: 'PUT',
        data,
      }),
    }),
    archiveBookingType: build.mutation<
      BookingType,
      {
        org_id: string
        serial: string
        config_id: string
        booking_type_id: string
      }
    >({
      query: ({ org_id, serial, config_id, booking_type_id }) => ({
        method: 'DELETE',
        url: `/${org_id}/${serial}/config/${config_id}/booking-types/${booking_type_id}`,
      }),
      invalidatesTags: (result, error, { booking_type_id }) => [
        { type: 'booking-types', id: booking_type_id },
      ],
    }),
    updateConfig: build.mutation<
      ZionVenueConfig,
      {
        org_id: string
        serial: string
        config_id: string
        data: Partial<ZionVenueConfig>
      }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (res) => {
        return [{ id: res?.serial, type: 'booking-config' }]
      },
    }),
    getVenues: build.query<
      MorpheusVenueWithinABookingContext[],
      { org_id: string }
    >({
      query: ({ org_id }) => ({
        url: `/morpheus/${org_id}/bookings/venues`,
        method: 'GET',
      }),
    }),
    getVenue: build.query<
      MorpheusVenueWithinABookingContext,
      { org_id: string; serial: string }
    >({
      query: ({ org_id, serial }) => ({
        url: `/morpheus/${org_id}/bookings/venues/${serial}`,
        method: 'GET',
      }),
    }),
    getSpecialDates: build.query<
      SpecialDate[],
      { org_id: string; serial: string; config_id: string }
    >({
      query: ({ org_id, serial, config_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/special-dates`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'special-dates',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'special-dates', id: 'LIST' },
            ]
          : [{ type: 'special-dates', id: 'LIST' }],
    }),
    createSpecialDate: build.mutation<
      SpecialDate,
      { org_id: string; serial: string; config_id: string; data: SpecialDate }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/special-dates`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'special-dates', id: 'LIST' }],
    }),
    updateSpecialDate: build.mutation<
      SpecialDate,
      {
        org_id: string
        serial: string
        config_id: string
        data: SpecialDate
        special_date_id: string
      }
    >({
      query: ({ org_id, serial, config_id, data, special_date_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/special-dates/${special_date_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { special_date_id }) => [
        { type: 'special-dates', id: special_date_id },
        { type: 'special-dates', id: 'LIST' },
      ],
    }),

    archiveSpecialDate: build.mutation<
      SpecialDate,
      {
        org_id: string
        serial: string
        config_id: string
        special_date_id: string
      }
    >({
      query: ({ org_id, serial, config_id, special_date_id }) => ({
        method: 'DELETE',
        url: `/${org_id}/${serial}/config/${config_id}/special-dates/${special_date_id}`,
      }),
      invalidatesTags: (result, error, { special_date_id }) => [
        { type: 'special-dates', id: special_date_id },
      ],
    }),
    getTables: build.query<Table[], { org_id: string; serial: string }>({
      query: ({ org_id, serial }) => ({
        url: `/${org_id}/${serial}/tables`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'tables',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'tables', id: 'LIST' },
            ]
          : [{ type: 'tables', id: 'LIST' }],
    }),
    getAreas: build.query<
      Area[],
      { org_id: string; serial: string; config_id: string }
    >({
      query: ({ org_id, serial, config_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'areas',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'areas', id: 'LIST' },
            ]
          : [{ type: 'areas', id: 'LIST' }],
    }),
    updateAreaPositions: build.mutation<
      Area[],
      { org_id: string; serial: string; config_id: string; data: Area[] }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas/positions`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [{ type: 'areas', id: 'LIST' }],
    }),
    createArea: build.mutation<
      Area,
      { org_id: string; serial: string; config_id: string; data: Area }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'areas', id: 'LIST' }],
    }),
    updateArea: build.mutation<
      Area,
      {
        org_id: string
        serial: string
        config_id: string
        area_id: string
        data: Area
      }
    >({
      query: ({ org_id, serial, config_id, area_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas/${area_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { area_id }) => [
        { type: 'areas', id: area_id },
        { type: 'areas', id: 'LIST' },
      ],
    }),
    archiveAreaType: build.mutation<
      Area,
      {
        org_id: string
        serial: string
        config_id: string
        area_id: string
      }
    >({
      query: ({ org_id, serial, config_id, area_id }) => ({
        method: 'DELETE',
        url: `/${org_id}/${serial}/config/${config_id}/areas/${area_id}`,
      }),
      invalidatesTags: (result, error, { area_id }) => [
        { type: 'areas', id: area_id },
      ],
    }),

    createTable: build.mutation<
      Table,
      {
        org_id: string
        serial: string
        config_id: string
        area_id: string
        data: Table
      }
    >({
      query: ({ org_id, serial, config_id, area_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas/${area_id}/tables`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (result, error, { area_id }) => [
        { type: 'areas', id: area_id },
      ],
    }),
    updateTable: build.mutation<
      Table,
      {
        org_id: string
        serial: string
        config_id: string
        area_id: string
        table_id: string
        data: Table
      }
    >({
      query: ({ org_id, serial, config_id, area_id, table_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas/${area_id}/tables/${table_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { area_id }) => [
        { type: 'areas', id: area_id },
        { type: 'tables', id: 'LIST' },
      ],
    }),
    archiveTable: build.mutation<
      Table,
      {
        org_id: string
        serial: string
        config_id: string
        area_id: string
        table_id: string
      }
    >({
      query: ({ org_id, serial, config_id, area_id, table_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas/${area_id}/tables/${table_id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, { area_id }) => [
        { type: 'areas', id: area_id },
        { type: 'tables', id: 'LIST' },
      ],
    }),
    updateTablePositions: build.mutation<
      Table[],
      {
        org_id: string
        serial: string
        config_id: string
        area_id: string
        data: Table[]
      }
    >({
      query: ({ org_id, serial, config_id, area_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/areas/${area_id}/tables/positions`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: [{ type: 'areas', id: 'LIST' }],
    }),
    getCombinations: build.query<
      Combination[],
      {
        org_id: string
        serial: string
        config_id: string
      }
    >({
      query: ({ org_id, serial, config_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/combinations`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'combinations',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'combinations', id: 'LIST' },
            ]
          : [{ type: 'combinations', id: 'LIST' }],
    }),
    createCombination: build.mutation<
      Combination,
      {
        org_id: string
        serial: string
        config_id: string
        data: Combination
      }
    >({
      query: ({ org_id, serial, config_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/combinations`,
        method: 'POST',
        data,
      }),
      invalidatesTags: [{ type: 'combinations', id: 'LIST' }],
    }),
    updateCombination: build.mutation<
      Combination,
      {
        org_id: string
        serial: string
        config_id: string
        combination_id: string
        data: Combination
      }
    >({
      query: ({ org_id, serial, config_id, combination_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/combinations/${combination_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { combination_id }) => [
        { type: 'combinations', id: combination_id },
      ],
    }),
    archiveCombination: build.mutation<
      Combination,
      {
        org_id: string
        serial: string
        config_id: string
        combination_id: string
      }
    >({
      query: ({ org_id, serial, config_id, combination_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/combinations/${combination_id}`,
        method: 'delete',
      }),
      invalidatesTags: (result, error, { combination_id }) => [
        { type: 'combinations', id: combination_id },
      ],
    }),
    getAvailability: build.query<
      Availability | undefined,
      {
        org_id: string
        serial: string
        config_id: string
        party_size: number
        booking_type: string
        date: string
        duration: string
        booking_id?: string
      }
    >({
      query: ({
        org_id,
        serial,
        config_id,
        party_size,
        booking_type,
        date,
        duration,
        booking_id,
      }) => ({
        url: buildUrl(`/${org_id}/${serial}/config/${config_id}/availability`, {
          booking_id,
          booking_type: booking_type === 'standard' ? null : booking_type,
          date,
          party_size,
          duration,
        }),
        method: 'GET',
      }),
      transformResponse: (data: Availability) => {
        if (isEmpty(data)) {
          return undefined
        }
        return data
      },
    }),
    createManualBooking: build.mutation<
      Booking,
      {
        org_id: string
        serial: string
        data: BookingAndDuration
      }
    >({
      transformResponse: (data: Booking) => {
        data.startDate = new Date(data.start_time)
        data.endDate = new Date(data.end_time)

        return data
      },
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/bookings/manual`,
        method: 'POST',
        data: {
          ...data,
        },
      }),
      invalidatesTags: [
        { type: 'daily-stats' },
        { type: 'cover-counts', id: 'LIST' },
      ],

      async onQueryStarted(
        { org_id, serial, data },
        { dispatch, queryFulfilled }
      ) {
        try {
          const newBooking = await queryFulfilled
          console.log('onQueryStarted', { newBooking })
          dispatch(
            zionApi.util.updateQueryData(
              'getBookingsByDate',
              {
                org_id,
                serial,
                date: format(new Date(data.start_time), 'yyyy-MM-dd'),
              },
              (draft) => {
                draft.push({
                  startDate: new Date(newBooking.data.start_time),
                  endDate: new Date(newBooking.data.end_time),
                  ...newBooking.data,
                })
              }
            )
          )
        } catch (error) {
          // Handle error
        }
      },
    }),
    updateManualBooking: build.mutation<
      BookingAndDuration,
      {
        org_id: string
        serial: string
        booking_id: string
        data: Booking & { attempt_cancel_charge?: boolean }
      }
    >({
      query: ({ org_id, serial, data, booking_id }) => {
        if (!('duration' in data)) {
          //@ts-ignore
          data.duration = differenceInSeconds(
            data.endDate,
            new Date(data.start_time)
          )
        }
        return {
          url: `/${org_id}/${serial}/bookings/manual/${booking_id}`,
          method: 'PUT',
          data: {
            ...data,
          },
        }
      },

      invalidatesTags: (_1, _2, { booking_id }) => [
        { type: 'daily-stats' },
        { type: 'activity', id: booking_id },
        { type: 'bookings', id: 'List' },
        { type: 'cover-counts', id: 'LIST' },
      ],

      onQueryStarted: async (
        { org_id, serial, booking_id, data },
        { dispatch, queryFulfilled }
      ) => {
        let end_time = add(new Date(data.start_time), {
          seconds: differenceInSeconds(
            new Date(data.end_time),
            new Date(data.start_time)
          ),
        }).toISOString()

        if (data.duration) {
          end_time = add(new Date(data.start_time), {
            seconds: data.duration,
          }).toISOString()
        }

        const updated_data = { ...data, end_time }
        const patchResult = dispatch(
          zionApi.util.updateQueryData(
            'getBookingsByDate',
            {
              org_id,
              serial,
              date: format(new Date(data.start_time), 'yyyy-MM-dd'),
            },
            (draft) => {
              const index = draft.findIndex(
                (booking) => booking.id === booking_id
              )
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  ...updated_data,
                  startDate: new Date(updated_data.start_time),
                  endDate: new Date(updated_data.end_time),
                }
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    fetchManualPayments: build.query<
      ManualPayment[],
      {
        org_id: string
        serial: string
        booking_id: string
      }
    >({
      query: ({ org_id, serial, booking_id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/manual-payments`,
        method: 'GET',
      }),
      providesTags: (res, error, { booking_id }) => {
        return [{ id: booking_id, type: 'manual-payments' }]
      },
    }),
    createManualPayment: build.mutation<
      ManualPayment,
      {
        org_id: string
        serial: string
        booking_id: string
        data: CreateManualPaymentPayload
      }
    >({
      query: ({ org_id, serial, data, booking_id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/manual-payments`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (k, t, { booking_id }) => [
        {
          type: 'manual-payments',
          id: booking_id,
        },
      ],
    }),
    deleteManualPayment: build.mutation<
      {},
      {
        org_id: string
        serial: string
        booking_id: string
        manual_payment_id: string
      }
    >({
      query: ({ org_id, serial, booking_id, manual_payment_id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/manual-payments/${manual_payment_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (k, t, { booking_id }) => [
        {
          type: 'manual-payments',
          id: booking_id,
        },
      ],
    }),
    resendConfirmationManualPayment: build.mutation<
      {},
      {
        org_id: string
        serial: string
        booking_id: string
        manual_payment_id: string
      }
    >({
      query: ({ org_id, serial, booking_id, manual_payment_id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/manual-payments/${manual_payment_id}/confirmation-email`,
        method: 'POST',
        data: {},
      }),
    }),
    getUserBookingsCount: build.query<
      { previous: number },
      { org_id: string; serial: string; email: string }
    >({
      query: ({ org_id, serial, email }) => ({
        url: `/${org_id}/${serial}/bookings/user/${email}/count`,
        method: 'GET',
      }),
    }),
    getBookingsByDate: build.query<
      Booking[],
      {
        org_id: string
        serial: string
        date: string
      }
    >({
      query: ({ org_id, serial, date }) => ({
        url: `/${org_id}/${serial}/bookings?date=${date}`,
        method: 'GET',
      }),
      transformResponse: (items: Booking[]) => {
        if (!items) return []
        return [...items]
          .map((item) => ({
            ...item,
            startDate: parseISO(item.start_time),
            endDate: parseISO(item.end_time),
          }))
          .sort((a, b) => a.startDate.getTime() - b.startDate.getTime())
      },
      providesTags: () => [{ type: 'bookings', id: 'List' }],
    }),
    getDurationFromPartySize: build.query<
      { duration: number },
      {
        org_id: string
        serial: string
        config_id: string
        party_size: number
        booking_type_id?: string | undefined
      }
    >({
      query: ({ org_id, serial, config_id, party_size, booking_type_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/party-size-duration/${party_size}?${booking_type_id ? `booking_type_id=${booking_type_id}` : ''}`,
        method: 'GET',
      }),
    }),
    getBookingActivityLog: build.query<
      ActivityLogs,
      {
        org_id: string
        serial: string
        booking_id: string
      }
    >({
      query: ({ org_id, serial, booking_id }) => ({
        url: `/${org_id}/${serial}/bookings/${booking_id}/activity-log`,
        method: 'GET',
      }),
      providesTags: (_, _1, { booking_id }) => [
        { type: 'activity', id: booking_id },
      ],
    }),
    getBooking: build.query<
      Booking & { startDate: Date; endDate: Date },
      {
        org_id: string
        serial: string
        booking_id: string
      }
    >({
      query: ({ org_id, serial, booking_id }) => ({
        url: `/${org_id}/${serial}/bookings/${booking_id}`,
        method: 'GET',
      }),
      transformResponse: (
        item: Booking & { startDate: Date; endDate: Date }
      ) => {
        const res = {
          ...item,
          allergens: item.allergens?.length ? item.allergens : [],
          booking_tables: item.booking_tables.map((item) => item.table_id),
          duration: differenceInSeconds(
            new Date(item.end_time),
            new Date(item.start_time)
          ),
          startDate: new Date(item.start_time),
          endDate: new Date(item.end_time),
        }
        //@ts-ignore
        return res
      },
    }),
    getBookingsDailyStats: build.query<
      { online: number; internal: number; pre_date: number; walk_in: number },
      {
        org_id: string
        serial: string
        date: string
      }
    >({
      query: ({ org_id, serial, date }) => ({
        url: `/${org_id}/${serial}/bookings/stats/daily?date=${date}`,
        method: 'GET',
      }),
      providesTags: (result, error, { org_id, serial, date }) => [
        { type: 'daily-stats', org_id, serial, date },
      ],
    }),

    swapBookings: build.mutation<
      Booking[],
      {
        org_id: string
        serial: string
        data: SwapManual
        updated_source_tables: string[]
        updated_target_tables: string[]
      }
    >({
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/bookings/manual/swap`,
        method: 'PUT',
        data: {
          ...data,
        },
      }),
      onQueryStarted: async (
        { org_id, serial, data, updated_source_tables, updated_target_tables },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          zionApi.util.updateQueryData(
            'getBookingsByDate',
            {
              org_id,
              serial,
              date: format(new Date(data.new_start), 'yyyy-MM-dd'),
            },
            (draft) => {
              const index = draft.findIndex(
                (booking) => booking.id === data.booking_source_id
              )
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  start_time: data.new_start,
                  end_time: data.new_end,
                  booking_tables: updated_source_tables,
                }
              }

              const index_2 = draft.findIndex(
                (booking) => booking.id === data.booking_target_id
              )
              if (index !== -1) {
                draft[index_2] = {
                  ...draft[index_2],
                  booking_tables: updated_target_tables,
                }
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    getBookingNotes: build.query<
      Internal_Booking_Note[],
      {
        org_id: string
        serial: string
        booking_id: string
      }
    >({
      query: ({ org_id, serial, booking_id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/notes`,
        method: 'GET',
      }),

      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'notes',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'notes', id: 'LIST' },
            ]
          : [{ type: 'notes', id: 'LIST' }],
    }),
    createBookingNotes: build.mutation<
      Internal_Booking_Note,
      {
        org_id: string
        serial: string
        booking_id: string
        data: {
          content: string
        }
      }
    >({
      query: ({ org_id, serial, booking_id, data }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/notes`,
        method: 'POST',
        data,
      }),
      invalidatesTags: (_, _1, { booking_id }) => [
        { type: 'notes', id: 'LIST' },
        { type: 'activity', id: booking_id },
      ],
    }),
    updateBookingNotes: build.mutation<
      Internal_Booking_Note,
      {
        org_id: string
        serial: string
        booking_id: string
        note_id: string
        data: {
          content: string
        }
      }
    >({
      query: ({ org_id, serial, booking_id, note_id, data }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/notes/${note_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { note_id, booking_id }) => [
        { type: 'notes', id: note_id },
        { type: 'activity', id: booking_id },
      ],
    }),
    getCloseOutsTimeAndTables: build.query<
      CloseOut[],
      {
        org_id: string
        serial: string
        date: string
      }
    >({
      query: ({ org_id, serial, date }) => ({
        url: `/${org_id}/${serial}/bookings/manual/close-outs?date=${date}`,
        method: 'GET',
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'close-outs',
                id: (id as string | undefined) ?? undefined,
              })),
              { type: 'close-outs', id: 'LIST' },
            ]
          : [{ type: 'close-outs', id: 'LIST' }],
    }),
    createCloseOut: build.mutation<
      CloseOut,
      {
        org_id: string
        serial: string
        data: {
          id: string
          start_time: string
          end_time: string
          tables: string[]
        }
      }
    >({
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/bookings/manual/close-outs`,
        method: 'POST',
        data,
      }),
      onQueryStarted: async (
        { org_id, serial, data },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          zionApi.util.updateQueryData(
            'getCloseOutsTimeAndTables',
            {
              org_id,
              serial,
              date: format(new Date(data.start_time), 'yyyy-MM-dd'),
            },
            (draft) => {
              return [...draft, data as any]
            }
          )
        )
        try {
          const { data: created_close_out } = await queryFulfilled
          dispatch(
            zionApi.util.updateQueryData(
              'getCloseOutsTimeAndTables',
              {
                org_id,
                serial,
                date: format(new Date(data.start_time), 'yyyy-MM-dd'),
              },
              (draft) => {
                return draft.map((close_out) => {
                  if (created_close_out.id === close_out.id) {
                    return created_close_out
                  }
                  return close_out
                })
              }
            )
          )
        } catch {
          patchResult.undo()
        }
      },
    }),
    updateCloseOut: build.mutation<
      CloseOut,
      {
        org_id: string
        serial: string
        data: {
          id: string
          start_time: string
          end_time: string
          tables: string[]
        }
      }
    >({
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/bookings/manual/close-outs/${data.id}`,
        method: 'PUT',
        data,
      }),
      onQueryStarted: async (
        { org_id, serial, data },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          zionApi.util.updateQueryData(
            'getCloseOutsTimeAndTables',
            {
              org_id,
              serial,
              date: format(new Date(data.start_time), 'yyyy-MM-dd'),
            },
            (draft) => {
              const index = draft.findIndex(
                (close_out) => close_out.id === data.id
              )
              if (index !== -1) {
                draft[index] = {
                  ...draft[index],
                  tables: data.tables,
                }
              }
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    removeCloseOut: build.mutation<
      {},
      {
        org_id: string
        serial: string
        id: string
        date: string
      }
    >({
      query: ({ org_id, serial, id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/close-outs/${id}`,
        method: 'delete',
      }),
      onQueryStarted: async (
        { org_id, serial, id, date },
        { dispatch, queryFulfilled }
      ) => {
        const patchResult = dispatch(
          zionApi.util.updateQueryData(
            'getCloseOutsTimeAndTables',
            {
              org_id,
              serial,
              date: format(new Date(date), 'yyyy-MM-dd'),
            },
            (draft) => {
              return draft.filter((close_out) => close_out.id !== id)
            }
          )
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
    sendInvite: build.mutation<
      {},
      {
        org_id: string
        serial: string
        booking_id: string
      }
    >({
      query: ({ org_id, serial, booking_id }) => ({
        url: `/${org_id}/${serial}/bookings/manual/${booking_id}/resend`,
        method: 'POST',
      }),
    }),

    getOpenClose: build.query<
      { open: string; close: string },
      {
        org_id: string
        serial: string
        date: string
      }
    >({
      query: ({ org_id, serial, date }) => ({
        url: `/${org_id}/${serial}/times/open-close?date=${date}`,
        method: 'GET',
      }),
    }),
    getReports: build.query<
      ReportingResponse,
      {
        org_id: string
        serial: string
        time_frame: TimeFrame
        date: string
        data_type: 'bookings' | 'covers'
      }
    >({
      query: ({ org_id, serial, date, data_type, time_frame }) => ({
        url: `/${org_id}/${serial}/reporting?date=${date}&time_frame=${time_frame}&data_type=${data_type}`,
        method: 'GET',
      }),
    }),
    getCustomerBookings: build.query<
      ServiceCursorPaginatedResponseType<Booking>,
      {
        org_id: string
        limit: number
        cursor: string
        profile_id: string | number
      }
    >({
      query: ({ org_id, profile_id, cursor, limit }) => ({
        url: buildUrl(`/${org_id}/user-profiles/${profile_id}/bookings`, {
          cursor,
          limit,
        }),
        method: 'GET',
      }),
    }),
    getCustomerBookingsCount: build.query<
      unknown,
      {
        org_id: string
        profile_id: string | number
      }
    >({
      query: ({ org_id, profile_id }) => ({
        url: `/${org_id}/user-profiles/${profile_id}/bookings-count`,
        method: 'GET',
      }),
    }),
    createReplies: build.query<
      ZionEvePromptReply,
      {
        org_id: string
        serial: string
        data: {
          first_name: string
          message: string
          context: 'booking_message_reply'
        }
      }
    >({
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/eve/prompts`,
        method: 'POST',
        data,
      }),
    }),
    modifyUserProfileSerialNote: build.mutation<
      {
        id: string
        note: string
        org_id: string
        serial: string
        user_profile_id: string
      },
      {
        org_id: string
        serial: string
        user_profile_id: string
        data: {
          note: string
        }
      }
    >({
      query: ({ org_id, serial, data, user_profile_id }) => ({
        url: `/${org_id}/${serial}/user-profiles/${user_profile_id}/note`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error, { user_profile_id }) => [
        { type: 'note', user_profile_id },
      ],
    }),
    getUserProfileSerialNote: build.query<
      {
        id: string
        note: string
        org_id: string
        serial: string
        user_profile_id: string
      },
      {
        org_id: string
        serial: string
        user_profile_id: string
      }
    >({
      query: ({ org_id, serial, user_profile_id }) => ({
        url: `/${org_id}/${serial}/user-profiles/${user_profile_id}/note`,
        method: 'GET',
      }),
      providesTags: (result, error, { user_profile_id }) => [
        { type: 'note', user_profile_id },
      ],
    }),
    getBookingInvites: build.query<
      ({
        user: {
          email: string
          first_name: string | null
          last_name: string | null
          phone: string | null
        }
      } & {
        id: string
        org_reg_id: string | null
        booking_id: string
        user_id: string
        status: InvitationStatus
        created_at: Date
        updated_at: Date | null
        deleted_at: Date | null
        relation: string
      })[],
      {
        org_id: string
        booking_id: string
        serial: string
      }
    >({
      query: ({ org_id, serial, booking_id }) => ({
        url: `/public/${org_id}/${serial}/booking/${booking_id}/invitations`,
        method: 'GET',
      }),
    }),
    getBookingsFromRange: build.query<
      any,
      {
        org_id: string
        serial: string
        start: string
        end: string
        cursor: string
        limit: number
        serial_filter: string | null | undefined
      }
    >({
      query: ({
        org_id,
        serial,
        start,
        end,
        cursor,
        limit,
        serial_filter,
      }) => ({
        url: `/${org_id}/${serial}/reporting/bookings?start=${start}&end=${end}${cursor ? `&cursor=${cursor}` : ``}&limit=${limit}&serial=${serial_filter}`,
        method: 'GET',
      }),
    }),
    getBookingsCountsFromRange: build.query<
      Stat[],
      {
        org_id: string
        serial: string
        start: string
        end: string
        serial_filter: string | null | undefined
      }
    >({
      query: ({ org_id, serial, start, end, serial_filter }) => ({
        url: `/${org_id}/${serial}/reporting/bookings/counts?start=${start}&end=${end}&serial=${serial_filter}`,
        method: 'GET',
      }),
    }),
    getCoversCountsFromRange: build.query<
      {
        name: string | null
        bookings_total: number
        covers: number
        covers_active: number
        emoji: string
      }[],
      {
        org_id: string
        serial: string
        start: string
        end: string
      }
    >({
      query: ({ org_id, serial, start, end }) => ({
        url: `/${org_id}/${serial}/reporting/bookings/cover_counts?start=${start}&end=${end}`,
        method: 'GET',
      }),
      providesTags: (result, error) => [{ type: 'cover-counts', id: 'LIST' }],
    }),
    getBDAScore: build.query<
      BDAScorePayload,
      {
        org_id: string
        serial: string
        start: string
        end: string
      }
    >({
      query: ({ org_id, serial, start, end }) => ({
        url: `/${org_id}/${serial}/retention?start=${start}&end=${end}`,
        method: 'GET',
      }),
    }),
    getAutomations: build.query<
      AutomationConfig[],
      {
        org_id: string
        serial: string
        config_id: string
      }
    >({
      query: ({ org_id, serial, config_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/automations`,
        method: 'GET',
      }),
      providesTags: (result, error) => [{ type: 'automations', id: 'LIST' }],
    }),
    getAutomation: build.query<
      AutomationConfig,
      {
        org_id: string
        serial: string
        config_id: string
        automation_source_id: string
      }
    >({
      query: ({ org_id, serial, config_id, automation_source_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/automations/${automation_source_id}`,
        method: 'GET',
      }),
    }),
    getAutomationStats: build.query<
      BookingAutomationStat[],
      {
        org_id: string
        serial: string
        config_id: string
      }
    >({
      query: ({ org_id, serial, config_id }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/automations/stats`,
        method: 'GET',
      }),
    }),
    modifyAutomation: build.mutation<
      AutomationConfig,
      {
        org_id: string
        serial: string
        config_id: string
        automation_source_id: string
        data: Partial<AutomationConfig>
      }
    >({
      query: ({ org_id, serial, config_id, automation_source_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/automations/${automation_source_id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: (result, error) => [{ type: 'automations', id: 'LIST' }],
    }),
    sendAutomationPreview: build.mutation<
      { message: string },
      {
        org_id: string
        serial: string
        config_id: string
        automation_source_id: string
        data: any
      }
    >({
      query: ({ org_id, serial, config_id, automation_source_id, data }) => ({
        url: `/${org_id}/${serial}/config/${config_id}/automations/${automation_source_id}/send-preview`,
        method: 'POST',
        data,
      }),
    }),
    getBookingTypeForTable: build.query<
      BookingType | null,
      {
        org_id: string
        serial: string
        table_id: string
        start_time: string
      }
    >({
      query: ({ org_id, serial, table_id, start_time }) => ({
        url: `/${org_id}/${serial}/tables/${table_id}/booking_type?start_time=${start_time}`,
        method: 'GET',
      }),
    }),
    getFloorPlans: build.query<
      FloorPlan[],
      {
        org_id: string
        serial: string
      }
    >({
      query: ({ org_id, serial }) => ({
        url: `/${org_id}/${serial}/floorplans`,
        method: 'GET',
      }),
      providesTags: (result, error) => [{ type: 'floorplans', id: 'LIST' }],
    }),
    getFloorPlan: build.query<
      FloorPlan,
      {
        org_id: string
        serial: string
        floor_plan_id: string
      }
    >({
      query: ({ org_id, serial, floor_plan_id }) => ({
        url: `/${org_id}/${serial}/floorplans/${floor_plan_id}`,
        method: 'GET',
      }),
      providesTags: (result, error) => [{ type: 'floorplans', id: result?.id }],
    }),
    createFloorPlan: build.mutation<
      FloorPlan,
      {
        org_id: string
        serial: string
        floor_plan: {
          name: string
          config_id: string
          tables: {
            table_id: string
            x: string
            y: string
            is_round: string
            rotation: string
          }[]
        }
      }
    >({
      query: ({ org_id, serial, floor_plan }) => ({
        url: `/${org_id}/${serial}/floorplans`,
        method: 'POST',
        data: floor_plan,
      }),

      invalidatesTags: () => [{ type: 'floorplans', id: 'LIST' }],
    }),
    updateFloorPlan: build.mutation<
      FloorPlan,
      {
        org_id: string
        serial: string
        data: {
          id: string
          name: string
          active: boolean
          tables?: {
            table_id: string
            x: number
            y: number
            is_round: boolean
            rotation: number
          }[]
        }
      }
    >({
      query: ({ org_id, serial, data }) => ({
        url: `/${org_id}/${serial}/floorplans/${data.id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: () => [{ type: 'floorplans', id: 'LIST' }],
    }),
    removeFloorPlan: build.mutation<
      {},
      {
        org_id: string
        serial: string
        floor_plan_id: string
      }
    >({
      query: ({ org_id, serial, floor_plan_id }) => ({
        url: `/${org_id}/${serial}/floorplans/${floor_plan_id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => [{ type: 'floorplans', id: 'LIST' }],
    }),
    updatefloorPlanPriorities: build.mutation<
      FloorPlan[],
      {
        org_id: string
        serial: string
        ids: string[]
      }
    >({
      query: ({ org_id, serial, ids }) => ({
        url: `/${org_id}/${serial}/floorplans/priorities`,
        method: 'PUT',
        data: { ids },
      }),

      invalidatesTags: (result, error) => [{ type: 'floorplans', id: 'LIST' }],
    }),
  }),
})

export default zionApi
