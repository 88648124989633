import { cva } from 'class-variance-authority'

export const buttonVariants = cva(
  `whitespace-nowrap inline-flex items-center font-semibold
    focus-visible:ring-primary-500 focus:outline-none focus-visible:ring

    transition-colors duration-75 disabled:cursor-not-allowed disabled:opacity-70`,
  {
    variants: {
      variant: {
        primary: [
          'bg-primary-500 text-white',

          'hover:bg-primary-600 hover:text-white',
          'active:bg-primary-800',
          'disabled:bg-primary-700',
        ],
        outline: [
          'text-primary-500',
          'border-primary-500 border-2',
          'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100',
        ],
        ghost: [
          'text-primary-500',

          'hover:bg-primary-50 active:bg-primary-100 disabled:bg-primary-100 dark:disabled:bg-gray-900 dark:hover:bg-gray-800/80',
        ],
        light: [
          'bg-white text-black/70',

          'hover:text-black hover:bg-black/5',
          'active:bg-black/10 disabled:bg-black/20',
          'dark:hover:bg-white/20 dark:active:bg-white/30',
        ],
        dark: [
          'bg-black/90 text-white',

          'hover:bg-black/80 active:bg-black/70 disabled:bg-black/90',
        ],
        default: [
          'bg-black/10 border border-black/5 text-black/80 backdrop-blur-lg',
          'aria-[current=page]:bg-white/80 dark:aria-[current=page]:bg-white/20 aria-[current=page]:text-black dark:aria-[current=page]:text-white',
          'hover:text-black hover:bg-black/15',
          'active:bg-white/80 active:text-white disabled:bg-black/20',
          'dark:bg-white/10 dark:text-white/70 dark:hover:text-white/90 dark:border-white/5',
          'disabled:text-white/90',
          'dark:hover:bg-white/15 dark:active:bg-white/20 dark:disabled:text-black/80 dark:disabled:bg-white/20',
        ],
        ghost_default: [
          'text-black/70 dark:text-white/70',
          'disabled:text-black/90',
          'hover:text-black hover:bg-black/10',
          'active:bg-white/80 disabled:bg-black/20',
          'dark:hover:bg-white/10 dark:active:bg-white/5 dark:disabled:bg-white/5 !min-h-[20px]',
        ],
        link: [
          'text-black/50 dark:text-white/40',

          '!px-0 !py-0',
          'hover:text-black',
          '!min-h-[20px]',
        ],
      },
      isDarkBg: {
        false: null,
        true: null,
      },
      isActive: {
        false: null,
        true: ['active'],
      },
      brandKit: {
        true: 'rounded-button !bg-interface-500 !text-brand-invert !font-body border-interface-600 hover:opacity-90 active:opacity-90 hover:bg-interface-700 active:bg-interface-600',
        false: 'rounded-full',
      },
      size: {
        base: ['px-4 py-1.5 min-h-[40px]', 'text-sm'],
        sm: ['px-2 py-1', 'text-xs  font-semibold'],
        lg: ['px-6 py-3', 'text-md md:text-base min-h-[48px]'],
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'base',
      isDarkBg: false,
      isActive: false,
      brandKit: false,
    },
    compoundVariants: [
      {
        brandKit: true,
        variant: ['ghost_default', 'link'],
        class: '!bg-transparent !text-brand',
      },
      {
        isActive: true,
        variant: ['default'],
        class: 'dark:bg-white/30 bg-black/20',
      },
      {
        isActive: true,
        variant: ['primary'],
        class: 'bg-primary-800',
      },
      {
        isActive: true,
        variant: ['ghost'],
        class: 'bg-primary-100',
      },
      {
        isActive: true,
        variant: ['light'],
        class: 'bg-black/10 dark:bg-white/30',
      },
      {
        isActive: true,
        variant: ['dark'],
        class: 'bg-black/70',
      },
      {
        isActive: true,
        variant: ['ghost_default'],
        class:
          'text-primary-500 bg-black/10 dark:bg-white/10 dark:hover:bg-white/20',
      },
      {
        isActive: true,
        isDarkBg: false,
        variant: ['outline'],
        class: 'bg-primary-100',
      },
      {
        brandKit: true,
        variant: ['outline'],
        class: '!bg-transparent !text-interface-500 !border-interface-500',
      },
      {
        isActive: true,
        isDarkBg: true,
        variant: ['outline'],
        class: 'bg-gray-800',
      },
      {
        brandKit: true,
        isActive: true,
        class: '!bg-interface-300 text-interface-500',
      },
      {
        isDarkBg: true,
        variant: ['outline', 'ghost'],
        class: 'hover:bg-black/90 active:bg-black/80 disabled:bg-black/80',
      },
    ],
  }
)
