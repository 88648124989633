import type { SimpleGridProps, StackProps } from '@chakra-ui/react'
import { Box, Button, HStack, Icon, Spacer } from '@chakra-ui/react'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  PlusIcon,
} from '@heroicons/react/16/solid'
import { cx } from 'class-variance-authority'
import { motion } from 'framer-motion'
import type { FunctionComponent, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { cn, Icn, Skeleton } from 'tailwind-ui'
import Description from 'tailwind-ui/src/typeography/description'
import Heading from 'tailwind-ui/src/typeography/heading'
import { ContentContainerMd } from '@/layouts/content/container'
import { generateImage } from '@/helpers/image'
import { BentoGrid } from './bento-grid'

const variants = {
  in: {
    opacity: 1,
    scale: 1,
    y: 0,
  },
  out: {
    // y: -50,
    scale: 0.8,
    opacity: 0,
  },
  enter: {
    opacity: 1,
    scale: 1,
  },
  exit: {
    //  y: -50,
    scale: 0.8,

    opacity: 0,
  },
}

const stringToId = (str = '') => str.replace(/ /g, '_').toLocaleLowerCase()

export function LayoutNavigationCard({
  route,
  name,
  icon,
  children,
  onClick,
  actions,
  isRounded = true,
  cover,
  bgColor,
  className,
  ...rest
}: {
  route?: string
  onClick?: () => void
  name: string
  icon?: JSX.Element
  actions?: ReactElement
  isRounded?: boolean
  cover?: string
  bgColor?: string
  className?: string
} & StackProps) {
  return (
    <div
      className={cx(
        'row-span-1   group/bento transition duration-200  border border-transparent justify-between flex flex-col space-y-4',
        className
      )}
    >
      <motion.div
        id={`layout_cards_item_${stringToId(name)}`}
        className={cx(
          'relative border-black/5 dark:border-white/5 border group overflow-hidden min-h-48 w-full h-full flex flex-1 flex-col justify-end hover:cursor-pointer',
          {
            'bg-black/10 dark:bg-white/10 ': !bgColor,
            'rounded-1.5-consentric': isRounded,
          }
        )}
        style={{ backgroundColor: bgColor }}
      >
        <Link
          to={route}
          onClick={onClick}
          className="absolute inset-0 flex p-1.5 items-end h-full w-full text-white"
        >
          <div className="">
            {cover ? (
              <img
                src={generateImage(cover, 'tile')}
                className="object-cover absolute bg-white/10 dark:bg-black/10 inset-0 w-full h-full group-hover:scale-125 transition-all duration-500"
                alt={name}
              />
            ) : null}
          </div>
          <div
            className={cx(
              'relative transition-all w-auto ease-in-out duration-250 rounded-xl  overflow-hidden flex flex-col',
              {
                'h-full justify-end flex-1': Boolean(children),
                'bg-white/25 backdrop-blur-xl dark:bg-black/25  border border-white/5':
                  !children,
              }
            )}
          >
            {children ? (
              <div
                className={cn(
                  'w-full ',
                  'absolute flex p-3 flex-col items-center justify-center h-full flex-1 rounded-t-xl',
                  'bg-white/15 backdrop-blur-md dark:bg-black/15'
                )}
              >
                {children}
              </div>
            ) : null}
            <div
              className={cx(
                'flex z-10 justify-between items-center pl-3 pr-1.5 py-1.5 rounded-b-xl'
              )}
            >
              <div className="flex gap-2 items-center  transition-colors duration-250 overflow-hidden  text-black dark:fill-gray-50 dark:text-gray-50 group-hover:fill-white">
                {icon ? icon : null}
                <p className="font-semibold text-sm truncate capitalize">
                  {name}
                </p>
              </div>
              <div className="flex items-center">
                <div
                  className="flex flex-row"
                  onClick={(e) => {
                    e.preventDefault()
                  }}
                >
                  {actions}
                </div>

                <Icn
                  size="lg"
                  icon={ArrowRightIcon}
                  className="bg-transparent rounded-1.5-consentric text-black dark:text-white border-transparent left-0 ml-2 group-hover:ml-4 relative   border-2 transition-all duration-500"
                  aria-label="go to"
                />
              </div>
            </div>
          </div>
        </Link>
      </motion.div>
    </div>
  )
}

export const LayoutNavigationAddCard: FunctionComponent<{
  title: string
  to: string
}> = ({ title, to, ...props }) => (
  <motion.div
    className="flex items-center justify-center h-full py-12 border border-dashed border-muted rounded-xl transition-background ease-in-out duration-250"
    {...props}
  >
    <Link to={to} className="flex flex-col items-center">
      <div className="flex items-center justify-center w-12 h-12 bg-brand-500 rounded-full shadow-lg">
        <PlusIcon className="w-6 h-6 text-white" />
      </div>
      <p className="mt-3">{title}</p>
    </Link>
  </motion.div>
)

export function LayoutClickAddCard({
  title,
  onClick,
  ...props
}: {
  title: string
  onClick: () => void
}) {
  return (
    <motion.div
      className="flex items-center justify-center h-full py-12 border border-dashed border-muted rounded-xl transition-background ease-in-out duration-250"
      {...props}
    >
      <div
        onClick={onClick}
        className="flex flex-col items-center cursor-pointer"
      >
        <div className="flex items-center justify-center w-12 h-12 bg-brand-500 rounded-full shadow-lg">
          <PlusIcon className="w-6 h-6 text-white" />
        </div>
        <p className="mt-3">{title}</p>
      </div>
    </motion.div>
  )
}

const tvariants = {
  in: {
    y: 0,

    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  out: {
    y: -150,

    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
}

export function CardsTitle({
  title,
  subTitle,
  goBack = '',
}: {
  title?: string
  subTitle?: string
  goBack?: string
}) {
  return (
    <HStack
      alignItems={{ base: 'start', md: 'center' }}
      flexDir={{ base: 'column', md: 'row' }}
      justify="space-between"
    >
      {Boolean(goBack) && (
        <Box flex="1">
          <Button
            as={Link}
            leftIcon={<Icon as={ArrowLeftIcon} />}
            to=".."
            variant="ghost"
          >
            {goBack}
          </Button>
        </Box>
      )}

      <Box
        animate="in"
        as={motion.div}
        flex="1"
        initial="out"
        textAlign={{
          base: 'left',
          md: 'left',
        }}
        variants={tvariants}
      >
        {subTitle ? <Description>{subTitle}</Description> : null}
        {title ? <Heading>{title}</Heading> : null}
      </Box>
      {Boolean(goBack) && <Spacer />}
    </HStack>
  )
}

export const gridItemProps = (
  image?: string | null,
  rotate = true,
  rounded = true
) => {
  if (!image) return {}
  return {
    _groupHover: {
      ':before': {
        transform: rotate ? 'rotate(7deg) scale(1.25)' : 'scale(1.25)',
      },
    },

    sx: {
      ':before': {
        ...(rounded && {
          borderRadius: {
            base: 'md',
            lg: 'xl',
          },
        }),

        bgColor: 'bg-surface',
        content: '""',
        boxSize: 'full',
        zIndex: 0,
        backgroundSize: 'cover',
        position: 'absolute',
        transform: rotate ? 'rotate(9deg) scale(1.2)' : 'scale(1)',
        transition: 'transform ease-in 250ms',
        backgroundPosition: 'center',
        bgImage: generateImage(image, 'tile'),
      },
    },
  }
}

export function LayoutCardsGrid({ className, ...props }: SimpleGridProps) {
  return (
    <BentoGrid
      className={cx(
        'grid grid-cols-1 md:grid-cols-2 w-full  lg:grid-cols-[repeat(auto-fill,minmax(280px,1fr))] gap-2',
        className
      )}
      {...props}
    />
  )
}

export const LayoutNavigationLoader: FunctionComponent<{ height?: string }> = ({
  height = '220px',
}) => {
  return (
    <ContentContainerMd>
      <div className="space-y-1">
        <Skeleton className="w-24 h-5 rounded-lg" />
        <Skeleton className="w-44 h-8 rounded-lg" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(200px,1fr))]">
        <Skeleton className="w-full  h-20" />
        <Skeleton className="w-full h-20" />
        <Skeleton className="w-full h-20" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
      <div className="grid gap-4  grid-cols-[repeat(auto-fill,minmax(300px,1fr))]">
        <Skeleton className="w-full  h-44" />
        <Skeleton className="w-full h-44" />
        <Skeleton className="w-full h-44" />
      </div>
    </ContentContainerMd>
  )
}

export default LayoutNavigationCard
