import { forwardRef, type DetailedHTMLProps, type HTMLAttributes } from 'react'
import { cn } from '../helpers/classnames'

interface H2WithRefProps extends React.HTMLAttributes<HTMLHeadingElement> {
  children: React.ReactNode
}

const Heading = forwardRef<HTMLHeadingElement, H2WithRefProps>(
  ({ className, ...props }, ref) => {
    return (
      <h2
        ref={ref}
        className={cn(
          'text-gray-700  font-brand  dark:text-gray-100 text-interface-900 dark:text-interface-50 text-xl  font-semibold max-w-sm  [text-wrap:balance] font-sans',
          className
        )}
        {...props}
      />
    )
  }
)

export default Heading
