import {
  Avatar,
  AvatarProps,
  Box,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Stack,
  Text,
} from '@chakra-ui/react'
import { TimeFormats } from '@/common/datepicker/utilities/formats'
import { format } from 'date-fns'
import { FunctionComponent, PropsWithChildren } from 'react'
import { InboxThreadType } from '@/state/entities/inbox/inbox.types'
import { gravatarImage } from '@/utils/common'

export const ChatAvatar: FunctionComponent<
  {
    email: string
    name: string
  } & AvatarProps
> = ({ name, email, ...rest }) => (
  <Avatar size="sm" src={gravatarImage(email)} name={name} {...rest} />
)

export const ThreadPopover: FunctionComponent<
  PropsWithChildren<{
    event: InboxThreadType['events']['data'][number]['eventable']
  }>
> = ({ children, event }) => {
  return (
    <Popover>
      <PopoverTrigger>{children}</PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />

          <PopoverBody fontSize="sm">
            {'user_name' in event && (
              <Stack>
                <Text>By: {event.user_name}</Text>
                <Text>
                  Date: {format(event.created_at, TimeFormats.StandardWithTime)}
                </Text>
              </Stack>
            )}

            {'contacts' in event && (
              <Stack>
                <Text>From:</Text>
                {event.contacts
                  .filter((it) => it.type === 'from')
                  .map((ite) => (
                    <Box key={ite.email}>
                      <Text fontWeight="bold">{ite.name}</Text>
                      <Text>{`<${ite.email}>`}</Text>
                    </Box>
                  ))}
                <Text>To:</Text>
                {event.contacts
                  .filter((it) => it.type === 'to')
                  .map((ite) => (
                    <Box key={ite.email}>
                      <Text fontWeight="bold">{ite.name}</Text>
                      <Text>{`<${ite.email}>`}</Text>
                    </Box>
                  ))}
                <Text>Cc:</Text>
                {event.contacts
                  .filter((it) => it.type === 'cc')
                  .map((ite) => (
                    <Box key={ite.email}>
                      <Text fontWeight="bold">{ite.name}</Text>
                      <Text>{`<${ite.email}>`}</Text>
                    </Box>
                  ))}
                <Text>
                  Date: {format(event.created_at, TimeFormats.StandardWithTime)}
                </Text>
                <Text>Subject: {event.subject}</Text>
              </Stack>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  )
}
