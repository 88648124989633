import { useEffect, useMemo, useState } from 'react'
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { BrandKitStyles, Spinner, cx } from 'tailwind-ui'
import { AvailableRoles } from '@/state/auth/auth.types'
import useSelectedOrganisation from '@/state/entities/organisations/hooks/useSelectedOrganisation'
import { setOrgId } from '@/state/global/global.actions'
import { useAppDispatch } from '@/state/helpers/useApp'
import { getFirst } from '@/utils/common'
import { usePusherSubscription } from '@/utils/pusher'
// import { customerApi } from './customer/:id/layout'
import { ScrollArea } from '@/common/scroll-area'
import Blurrer from '@/components/Pii/Blurrer'
import { useAlertContext } from '@/components/alerts'
import { InboxModalProvider } from '@/components/inbox/context/modal'
import { zEOpen } from '@/helpers/zendesk'
import useSubscription from '@/hooks/useSubscription'
import useUser, { useUserRole } from '@/hooks/useUser'
import { UserInviteProvider } from '@/providers/user-invite.context'
import { LegacyPlanTypes } from '@/state/billing/billing.types'
import inboxApi from '@/state/inbox/inbox.slice'
import { useUserConfig } from '@/state/morpheus/user-config/helpers'
import { usePageViewLogger } from '@/utils/tracking'
import { ActionBar } from './action-bar'
//import style from './app.module.css'

const showNavOn = [
  '/',
  '/venues',
  '/marketing',
  '/reviews',
  '/gifting',
  '/loyalty',
  '/reports',
  '/bookings',
  '/venues/:serial/booking',
  '/venues/:serial/loyalty',
]

const hideNavOn = [
  '/marketing/email/campaigns/:id',
  '/marketing/email/templates/:id',
  '/venues/:serial/loyalty/:id/service/*',
  '/venues/:serial/loyalty/:id/customers/:customer_id',
  '/venues/:serial/bookings/day-planner',
  '/venues/:serial/bookings/list',
  '/venues/:serial/bookings/floor-plans-edit',
  '/venues/:serial/bookings/floor-plans',
  '/venues/:serial/site/*',
]

function DashLayout() {
  const { pathname } = useLocation()

  // const shouldHaveApp = useShouldHaveApp()
  const organisation = useSelectedOrganisation()
  const user = useUser()
  const role = useUserRole()
  const push = useNavigate()
  const dispatch = useAppDispatch()
  const subscription = useSubscription()
  const [alerts, setAlerts] = useAlertContext()

  usePageViewLogger()
  const hiddenOn = useMemo(() => {
    return (
      hideNavOn
        .map((item) => matchPath(`/:org_id${item}`, pathname))
        .filter((item) => Boolean(item)).length > 0
    )
  }, [pathname])

  const { org_id } = useParams()

  useEffect(() => {
    dispatch(setOrgId(org_id))
  }, [org_id, dispatch])

  useEffect(() => {
    if (!role) return
    if (pathname.includes('bookings')) return
    if (role.name !== AvailableRoles.Booking) return
    if (!organisation?.locations) return
    const location = getFirst(organisation.locations)
    if (matchPath(':org_id/', pathname)) {
      //  push('bookings')
      push(`venues/${location.serial}/bookings`)
      // push(`venues/${location.serial}/bookings`)
    }
  }, [role, pathname, push, organisation])

  usePusherSubscription<{ open: number; closed: number }>(
    'inbox.thread.unread_count.updated',
    () => {
      if (!organisation) return
      dispatch(inboxApi.util.invalidateTags(['counts']))
    }
  )

  const initialModalOpen = useMemo(() => {
    if (!organisation) return false
    if (!subscription) return false
    return !subscription && organisation.type !== 'root'
  }, [subscription, organisation])

  const [isModalOpen, setIsModalOpen] = useState(initialModalOpen)

  useEffect(() => {
    setIsModalOpen(initialModalOpen)
  }, [initialModalOpen])

  const isSubscriptionTrial = useMemo((): boolean => {
    // return true
    if (!subscription) return false
    if (subscription.legacy_plan === LegacyPlanTypes.SelfServe) {
      return false
    }
    return subscription.subscription.is_trial
  }, [subscription])

  useEffect(() => {
    if (!isSubscriptionTrial) return
    setAlerts({
      itemKey: 'trial',
      type: 'success',
      title: `You are currently in trial. Be sure to add a payment method for
      when your account rolls over`,
      to: `organisation/billing`,
      isDismissible: true,
    })
  }, [isSubscriptionTrial, setAlerts])

  useEffect(() => {
    if (!subscription) return
    if (subscription?.needs_contact_upgrade > 0) {
      setAlerts({
        itemKey: 'contacts',
        type: 'warning',
        title: `Additional ${subscription?.needs_contact_upgrade} contacts required, upgrade to continue using marketing, wifi and inbox`,
        onClick: zEOpen,
      })
    }
    if (subscription?.needs_venue_upgrade > 0) {
      setAlerts({
        itemKey: 'venue',
        title: `Additional ${subscription?.needs_venue_upgrade} venues required, upgrade to continue using wifi, reviews and marketing`,

        type: 'warning',
        onClick: zEOpen,
      })
    }
  }, [subscription, setAlerts])

  const isSubscriptionActive = useMemo((): boolean => {
    // for legacy orgs without a subscription record.
    // return false
    if (!subscription) {
      return true
    }

    return subscription.active
  }, [subscription])

  useEffect(() => {
    if (isSubscriptionActive) return
    setAlerts({
      itemKey: 'subscription',
      title: `Your account is suspended due to overdue payment. Activate your subscription by making payment now`,
      type: 'error',
      to: `organisation/billing`,
    })
  }, [isSubscriptionActive, setAlerts])

  const config = useUserConfig()

  const branding = useMemo(() => {
    if (!organisation?.locations) return null
    const locationsWithBranding = organisation.locations.filter(
      (item) => item?.branding_settings.is_default
    )
    if (locationsWithBranding.length === 0) return null

    return getFirst(locationsWithBranding)?.branding_settings
  }, [organisation])

  if (!organisation || !user)
    return (
      <div className="flex items-center justify-center min-h-screen h-screen w-full  flex-1">
        <Spinner />
      </div>
    )

  return (
    <UserInviteProvider key="UserInviteProvider">
      {branding ? <BrandKitStyles ignoreBody brand={branding} /> : null}
      <InboxModalProvider>
        {!hiddenOn && config.action_bar.is_fixed ? (
          <div
            className={cx('grid overflow-hidden flex-1', {
              'md:grid-cols-[60px_1fr]':
                (config.action_bar.icon || !config.action_bar.label) &&
                !['top', 'bottom'].includes(config.action_bar.position),
              'md:grid-cols-[auto_1fr]':
                config.action_bar.label &&
                !['top', 'bottom'].includes(config.action_bar.position),
              'grid-cols-1 grid-rows-auto':
                config.action_bar.is_fixed &&
                ['top', 'bottom'].includes(config.action_bar.position),
              'grid-cols-[auto_70px]':
                config.action_bar.is_fixed &&
                ['right'].includes(config.action_bar.position) &&
                !config.action_bar.label,
              'grid-cols-[1fr_auto]':
                config.action_bar.is_fixed &&
                ['right'].includes(config.action_bar.position) &&
                config.action_bar.label,
            })}
          >
            <ScrollArea
              style={{ minHeight: 'auto' }}
              className={cx(
                'max-md:fixed max-md:w-full max-md:bottom-0 pb-safe-bottom-half',
                'bg-white/50 dark:bg-black/50 backdrop-blur-lg z-50 flex items-start overflow-x-hidden',
                {
                  'w-full':
                    config.action_bar.is_fixed &&
                    ['top', 'bottom'].includes(config.action_bar.position),
                },
                {
                  'order-2':
                    config.action_bar.is_fixed &&
                    ['right', 'bottom'].includes(config.action_bar.position),
                  '': !config.action_bar.is_fixed,
                  'lg:p-2': config.action_bar.label,
                }
              )}
            >
              <ActionBar />
            </ScrollArea>
            <div className="overflow-auto size-full flex flex-col flex-1 order-1 mb-24 md:mb-0">
              <Outlet />
            </div>
          </div>
        ) : (
          <>
            {!hiddenOn && <ActionBar />}

            <div className="overflow-auto h-full w-full flex flex-col mb-24 md:mb-0">
              <Outlet />
            </div>
          </>
        )}

        <Blurrer />
      </InboxModalProvider>
    </UserInviteProvider>
  )
}

export default DashLayout
