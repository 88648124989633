import { useState, useEffect, useLayoutEffect } from 'react'
import { useColorMode as useLegacyColorMode } from '@chakra-ui/react'
import Cookies from 'js-cookie'

const applyMetaColor = (color: string) => {
  const metas = document.getElementsByTagName('meta')
  for (let i = 0; i < metas.length; i += 1) {
    if (metas.item(i).name === 'theme-color') {
      metas.item(i).content = color
    }
  }
}

export const useColorMode = () => {
  const [isDarkMode, setIsDarkMode] = useState(
    Cookies.get('theme') === 'dark'
      ? true
      : window.matchMedia('(prefers-color-scheme: dark)').matches
  )
  useLayoutEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark')
      // document.body.classList.add('dark')
      // document.body.classList.add('dark')
      //  localStorage.setItem('theme', 'dark')
      applyMetaColor('#000000')
      Cookies.set('theme', 'dark')
    } else {
      document.documentElement.classList.remove('dark')
      //  document.body.classList.remove('dark')
      // document.body.classList.remove('dark')
      // localStorage.setItem('theme', 'light')
      applyMetaColor('#FFFFFF')
      Cookies.set('theme', 'light')
    }
  }, [isDarkMode])

  /**
   *
   *
   * Backward compat support for chakra
   */
  const { setColorMode } = useLegacyColorMode()

  useEffect(() => {
    if (!setColorMode) return
    setColorMode(isDarkMode ? 'dark' : 'light')
  }, [isDarkMode, setColorMode])

  useEffect(() => {
    // Add listener for system color scheme changes
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')

    const handleChange = (e: MediaQueryListEvent) => {
      // Only update if there's no explicit user preference stored in cookies

      //if (Cookies.get('theme') !== 'dark') {
      setIsDarkMode(e.matches)
      // }
    }

    mediaQuery.addEventListener('change', handleChange)

    // Cleanup listener on unmount
    return () => {
      mediaQuery.removeEventListener('change', handleChange)
    }
  }, [])

  return [isDarkMode, setIsDarkMode] as const
}
