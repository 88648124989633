'use client'
import React, { forwardRef } from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { cva, type VariantProps } from 'class-variance-authority'
import { type ClassValue, cn, Icn, type SvgIcon, FadeInOut } from 'tailwind-ui'
import { ErrorLabel } from '../labels/error'
import Label from '../labels/label'

export interface InputProps
  extends Omit<
      React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
      >,
      'size'
    >,
    VariantProps<typeof inputVariants> {
  error?: string | boolean
  isTouched?: boolean
  leftIcon?: SvgIcon
  rightIcon?: SvgIcon
  rightElement?: React.ReactNode
  leftElement?: React.ReactNode
  leftInlineAddOn?: string
  label?: string
  subClasses?: {
    rightClass?: ClassValue
    leftClass?: ClassValue
    label?: ClassValue
    container?: ClassValue
  }
}

export const inputVariants = cva(
  [
    'flex placeholder-black/70 dark:placeholder-white/70 appearance-none form-input border-black/5 dark:border-white/5 w-full border-2 text-black   bg-white/70 dark:bg-black/70 dark:text-white',
    'disabled:cursor-not-allowed disabled:opacity-50',
    'focus:ring-stampede-500 focus:border-stampede-500',
    'dark:focus:border-stampede-500',
  ],
  {
    variants: {
      size: {
        sm: 'px-2 h-9 text-sm',
        base: 'px-3 h-10',
        lg: 'h-12 px-4',
        xl: 'h-14 py-3 px-4',
      },
      brandKit: {
        true: 'rounded-button !focus:border-interface-500 !focus:ring-interface-500 dark:focus:border-stampede-500',
        false: 'rounded-lg',
      },
      errorLabel: {
        true: null,
        false: null,
      },
    },
    defaultVariants: {
      size: 'base',
      brandKit: false,
      errorLabel: false,
    },
  }
)

const GREEN_SUCCESS =
  'focus:ring-green-500 bg-green-50 ring-green-500 border-green-500 text-green-900 placeholder-green-700 focus:border-green-500'
const PRIMARY_SUCCESS =
  'focus:ring-stampede-500 border-stampede-500 focus:border-stampede-500'
const ERROR_CLASS = 'border-red-500'
// focus:border-red-500 focus:ring-red-500

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      error,
      isTouched,
      className,
      subClasses,
      brandKit,
      size,
      errorLabel,
      leftIcon,
      rightIcon,
      rightElement,
      leftElement,
      leftInlineAddOn,
      label,
      ...rest
    },
    ref
  ) => {
    const isError = Boolean(error && isTouched)
    const isSuccess = Boolean(!error && isTouched)

    return (
      <div className={cn('w-full group space-y-1', subClasses?.container)}>
        {label ? (
          <Label htmlFor={rest.id} className={cn(subClasses?.label)}>
            {label}
          </Label>
        ) : null}
        <div className="relative">
          {leftIcon ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <Icn
                size={size === 'xl' ? 'lg' : size}
                className={cn('text-gray-400', subClasses?.leftClass)}
                aria-hidden="true"
                icon={leftIcon}
              />
            </div>
          ) : null}
          {leftInlineAddOn ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span>{leftInlineAddOn}</span>
            </div>
          ) : null}
          <input
            type="text"
            className={cn(
              inputVariants({ brandKit, size, className }),
              isError && ERROR_CLASS,
              isSuccess ? (brandKit ? GREEN_SUCCESS : PRIMARY_SUCCESS) : null,
              leftIcon
                ? ['lg', 'xl'].includes(size ?? '')
                  ? 'pl-12'
                  : 'pl-10'
                : null,
              rightIcon && 'pr-9',
              className
            )}
            {...rest}
            ref={ref}
          />
          {rightIcon ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <Icn
                size={size === 'xl' ? 'lg' : size}
                className={cn('text-gray-400', subClasses?.rightClass)}
                aria-hidden="true"
                icon={rightIcon}
              />
            </div>
          ) : null}
          {rightElement ? (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              {rightElement}
            </div>
          ) : null}
          {leftElement ? (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 pr-3">
              {leftElement}
            </div>
          ) : null}
          <FadeInOut
            show={isSuccess ? !rightIcon : null}
            className={cn(
              'rounded-full bg-stampede-500 flex items-center justify-center absolute  right-2 top-1/2 -translate-y-1/2',
              brandKit && 'bg-green-500',
              'w-6 h-6',
              {
                'w-8 h-8': size === 'lg',
                'w-6 h-6': size === 'base',
              }
            )}
          >
            <Icn
              icon={CheckIcon}
              className="text-white"
              size={size === 'xl' ? 'lg' : size}
            />
          </FadeInOut>
          <FadeInOut
            className="h-5 w-5 rounded-full bg-red-500 flex items-center justify-center absolute right-2 top-1/2 -translate-y-1/2"
            show={isError ? !rightIcon : null}
          >
            <span className="text-white text-center leading-none">!</span>
          </FadeInOut>

          {errorLabel && isError ? <ErrorLabel error={error} /> : null}
        </div>
        {!errorLabel && isError ? (
          <p className="mt-0.5 text-xs text-red-600">{error}</p>
        ) : null}
      </div>
    )
  }
)

Input.displayName = 'Input'
