// Sentry initialization should be imported first!
import 'react-app-polyfill/stable'

import Cookies from 'js-cookie'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { RouterProvider } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { Spinner } from 'tailwind-ui'
import store from '@/state/store'
import './index.css'
import { sentryCreateBrowserRouter } from './instrument'
import { routes } from './routes'

const createStore = store()

if (window.location.hostname === 'localhost') {
  //  document.domain = 'localhost'
}

// old site - when we are ready we will force people over to the new stampede site
if (window.location.hostname === 'product.blackbx.io') {
  window.location.href = `https://product.stampede.ai${window.location.pathname}${window.location.search}`
}

if (window.location.hostname.startsWith('connect')) {
  window.location.href = `https://${window.location.hostname.replace(
    'connect',
    'product'
  )}/${window.location.pathname}`
}

const root = createRoot(document.getElementById('root')!)

const router = sentryCreateBrowserRouter(routes)

if (import.meta.hot) {
  import.meta.hot.dispose(() => {
    router.dispose()
  })
}

if (
  Cookies.get('theme') === 'dark'
    ? true
    : window.matchMedia('(prefers-color-scheme: dark)').matches
) {
  document.documentElement.classList.add('dark')
}

root.render(
  <StrictMode>
    <ReduxProvider store={createStore.store}>
      <PersistGate
        loading={
          <div className="flex items-center justify-center min-h-screen h-screen w-full flex-1">
            <Spinner />
          </div>
        }
        persistor={createStore.persistor}
      >
        <RouterProvider
          fallbackElement={
            <div className="'flex items-center justify-center min-h-screen h-screen w-full flex-1'">
              <Spinner />
            </div>
          }
          router={router}
        />
      </PersistGate>
    </ReduxProvider>
  </StrictMode>
)
