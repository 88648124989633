'use client'
import { ElementType, Fragment, forwardRef } from 'react'
import { Field, Switch as HSwitch, Label, SwitchProps } from '@headlessui/react' // Assuming you're using @headlessui
import { DetailedHTMLProps } from 'react'
import { cn } from '../..'

type MyToggleProps = {
  brandKit?: boolean
  label?: { true: string; false: string } | null
  classNames?: {
    container?: string
  }
} & SwitchProps<HTMLInputElement>

const Switch = forwardRef<HTMLInputElement, MyToggleProps>(
  (
    {
      brandKit = false,
      label = { true: 'On', false: 'Off' },
      classNames = { container: '' },
      ...rest
    },
    ref
  ) => {
    return (
      <Field>
        <div className={cn('flex items-center', classNames.container)}>
          {label && (
            <Label className="mr-4 capitalize block text-sm font-medium leading-6 text-black/80 dark:text-white/80">
              {rest?.checked ? label.true : label.false}
            </Label>
          )}

          <HSwitch as={Fragment} ref={ref} {...rest}>
            {({ checked, disabled }) => (
              <button
                className={cn(
                  'relative inline-flex h-6 w-11 items-center rounded-full',
                  checked
                    ? brandKit
                      ? 'bg-interface-500'
                      : 'bg-stampede-500'
                    : 'bg-black/20 dark:bg-white/20',
                  disabled && 'cursor-not-allowed opacity-50'
                )}
              >
                {label && (
                  <span className="sr-only">
                    {checked ? label.true : label.false}
                  </span>
                )}
                <span
                  className={cn(
                    'size-4 rounded-full bg-white dark:bg-gray-300 transition',
                    checked ? 'translate-x-6' : 'translate-x-1'
                  )}
                />
              </button>
            )}
          </HSwitch>
        </div>
      </Field>
    )
  }
)

export default Switch

Switch.displayName = 'Switch'
